import PropTypes from 'prop-types';

import './style.css';

const Step = (props) => {
    const { left, dot, right } = props;

    return (
        <>
            <div className="cc-stepper-step">
                <div className={`cc-stepper-bar ${left}`} />
                <div className={`cc-stepper-dot ${dot}`} />
                <div className={`cc-stepper-bar ${right}`} />
            </div>
        </>
    );
};

Step.propTypes = {
    dot: PropTypes.oneOf([
        'active', 'disabled',
    ]),
    left: PropTypes.oneOf([
        'active', 'hidden', 'disabled',
    ]),
    right: PropTypes.oneOf([
        'active', 'hidden', 'disabled',
    ]),
    text: PropTypes.string,
};

Step.defaultProps = {
    dot: 'disabled',
    left: 'disabled',
    right: 'disabled',
};

export default Step;
