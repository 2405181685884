import React from 'react';
import _ from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useSubscriptions from '../../hooks/use_subscriptions';

import Title from '../../components/title';
import Loader from '../../components/loader';
import SubscriptionCard from '../../components/subscription_card';
import EmptyState from '../../components/empty_state';

import MainIcon from '../../assets/icons/connect-car-icon.svg';
import MissingKeys from '../../assets/images/missing-keys.jpg';
import { ACTIVE_STATUSES, PENDING_STATUSES, OTHER_STATUSES } from '../../constants/subscription_status';
import Button from '../../components/button';

const Page = () => {
    const [subscriptions] = useSubscriptions();

    const renderSubscriptions = (title, subscriptions) => {
        if (_.isEmpty(subscriptions) || !_.isArray(subscriptions)) return null;

        const body = subscriptions.map((subscription, index) => {
            return (
                <Col key={subscription.id} xl={4} className="mb-3">
                    <SubscriptionCard subscription={subscription} index={index} />
                </Col>
            );
        });

        return (
            <Row>
                <Col>
                    <br/>
                    <br/>
                    <Title size="sm" variant="primaryNewTheme">
                        { title }
                    </Title>
                    <br/>
                    <Row>
                        { body }
                    </Row>
                </Col>
            </Row>
        );
    };

    const renderSubscriptionsBlock = (subscriptions) => {
        if (_.isUndefined(subscriptions)) {
            return (
                <>
                    <br />
                    <br />
                    <Loader icon={MainIcon}>
                        <p>Cargando tus suscripciones...</p>
                    </Loader>
                </>
            );
        }

        const activeSubscriptions = (subscriptions ?? []).filter(subscription => ACTIVE_STATUSES.includes(subscription.status));

        const pendingSubscriptions = (subscriptions ?? []).filter(subscription => PENDING_STATUSES.includes(subscription.status));

        const endedSubscriptions = (subscriptions ?? []).filter(subscription => OTHER_STATUSES.includes(subscription.status));

        if (_.isEmpty(activeSubscriptions) && _.isEmpty(pendingSubscriptions) && _.isEmpty(endedSubscriptions)) {
            return (
                <div className="mt-5" style={{ position: 'relative' }}>
                    <br/>
                    <br/>
                    <EmptyState
                        block
                        title="Aún no tienes ninguna suscripción"
                        src={MissingKeys}
                        subtitle="Disfruta la nueva experiencia de connect car"
                    >
                        <br/>
                        <Button
                            variant="primary"
                            href="https://connectcar.cl/cars"
                        >
                            Buscar mi auto
                        </Button>
                    </EmptyState>
                </div>
            );
        }

        return (
            <>
                { renderSubscriptions('Tus suscripciones activas', activeSubscriptions) }
                { renderSubscriptions('Tus suscripciones en proceso', pendingSubscriptions) }
                { renderSubscriptions('Tus suscripciones finalizadas', endedSubscriptions) }
            </>
        );
    };

    return (
        <>
            <Title variant="secondaryNewTheme">
                Mis suscripciones
            </Title>
            { renderSubscriptionsBlock(subscriptions) }
        </>
    );
};

export default Page;
