import axios from 'axios';

import sessionService from './session_service';
import { toCamelKeys, toSnakeKeys, toKebabCase } from '../helpers/camelizer';
import { NotificationService } from './notification_service';

const { REACT_APP_API_HOST, REACT_APP_CLIENT_ID, REACT_APP_CLIENT_TYPE } = process.env;

const API = axios.create({
    baseURL: REACT_APP_API_HOST,
    timeout: 30000,
});

API.interceptors.request.use((config) => {
    const token = sessionService.getSessionToken();

    if (token) {
        config.headers.authorization = token;
    }

    if (REACT_APP_CLIENT_ID && REACT_APP_CLIENT_TYPE) {
        config.headers['cc-fast-client-id'] = REACT_APP_CLIENT_ID;
        config.headers['cc-fast-client-type'] = REACT_APP_CLIENT_TYPE;
    }

    return config;
});

API.interceptors.response.use(undefined, async (error) => {
    const notificationService = NotificationService();

    if (error?.response?.status === 403) {
        const message = 'You are not authorized';

        notificationService.dispatch(error?.response?.data?.error || message);
    }

    if (error?.response?.data?.error === 'Invalid token provided') {
        sessionService.removeSession();
    }

    if (error.code === 'ERR_CANCELED') return undefined;

    if (error.code === 'ERR_NETWORK') {
        notificationService.dispatch("We couldn't connect to the server");
    }

    const data = error?.response?.data;

    if (typeof data?.error === 'string') {
        throw new Error(data.error);
    }

    throw new Error('Unknown error happened');
});

const transformToSnakeKeys = (data) => {
    let body;

    if (data instanceof FormData) {
        const jsonData = {};

        data.forEach((value, key) => {
            jsonData[key] = value;
        });

        const snakeData = toSnakeKeys(jsonData);

        body = new FormData();

        Object.entries(snakeData).forEach(([key, value]) => {
            body.append(key, value);
        });

        return body;
    }

    return toSnakeKeys(data);
};

const GET = async (path, query, cancelToken) => {
    const response = await API.get(path, { cancelToken: cancelToken?.token, params: query });

    return toCamelKeys(response?.data);
};

const UPDATE = async (path, data, cancelToken) => {
    const body = transformToSnakeKeys(data);

    const options = {
        cancelToken: cancelToken?.token,
        timeout: 1000 * 60 * 5,
    };

    const response = await API.put(path, body, options);

    return toCamelKeys(response?.data);
};

const POST = async (path, data, cancelToken) => {
    const body = transformToSnakeKeys(data);

    const options = {
        cancelToken: cancelToken?.token,
        timeout: 1000 * 60 * 5,
    };

    const response = await API.post(path, body, options);

    return toCamelKeys(response?.data);
};

const DELETE = async (path, cancelToken) => {
    const response = await API.delete(path, { cancelToken: cancelToken?.token });

    return toCamelKeys(response?.data);
};

class ApiService {
    start() {
        return axios.CancelToken.source();
    }

    cancel(cancelToken) {
        cancelToken?.cancel();
    }

    async login(email, password, cancelToken) {
        const path = '/login';

        const response = await POST(path, { email, password }, cancelToken);

        sessionService.storeSessionUserId(response.data.userId, response.data.expiration);
        sessionService.storeSessionToken(`Bearer ${response.data.token}`, response.data.expiration);

        return response;
    }

    getSchema(collectionName, method, cancelToken) {
        const path = `/${toKebabCase(collectionName)}/__schema__`;

        return GET(path, { method }, cancelToken);
    }

    findAll(collectionName, query, cancelToken) {
        const path = `/${toKebabCase(collectionName)}`;

        return GET(path, query, cancelToken);
    }

    findOne(collectionName, id, query, cancelToken) {
        const path = `/${toKebabCase(collectionName)}/${id}`;

        return GET(path, query, cancelToken);
    }

    update(collectionName, id, data, cancelToken) {
        const path = `/${toKebabCase(collectionName)}/${id}`;

        return UPDATE(path, data, cancelToken);
    }

    post(collectionName, data, cancelToken) {
        const path = `/${toKebabCase(collectionName)}`;

        return POST(path, data, cancelToken);
    }

    delete(collectionName, id, cancelToken) {
        const path = `/${toKebabCase(collectionName)}/${id}`;

        return DELETE(path, cancelToken);
    }
}

const apiService = new ApiService();

export default apiService;
