/* eslint-disable react/prop-types */

import Modal from 'react-bootstrap/Modal';

import Paragraph from '../paragraph';

import './style.css';

const IconModal = (props) => {
    const { show, setShow, icon, title, children } = props;

    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={() => setShow(false)}
            centered
            bsPrefix="rounded-modal modal"
        >
            <Modal.Body>
                <div className="icon-container">
                    <div>
                        { icon }
                    </div>
                    <Paragraph textAlign="center" fontWeight="bold" size="md">
                        { title }
                    </Paragraph>
                </div>
                { children }
            </Modal.Body>
        </Modal>
    );
};

export default IconModal;
