import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

import InfoIcon from '../../../assets/icons/info-icon.svg';

const BaseCard = (props) => {
    const {
        children,
        cardType,
        title,
        className,
    } = props;

    const renderIconType = () => {
        switch (cardType) {
        case 'info':
            return <img src={InfoIcon} alt="info" className="card-icon" />;
        default:
            return null;
        }
    };


    return (
        <div
            className={`base-card ${className}`}
        >
            <div className="title-wrapper">
                {renderIconType()}
                <span className="title">{title}</span>
            </div>
            <div className="content">
                { children }
            </div>
        </div>
    );
};

BaseCard.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    cardType: PropTypes.oneOf(['success', 'danger', 'warning', 'info']),
    title: PropTypes.string,
};

BaseCard.defaultProps = {
    className: '',
    cardType: 'info',
    title: 'Importante',
};

export default BaseCard;
