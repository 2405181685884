import React from 'react';
import PropTypes from 'prop-types';

const BaseParagraph = (props) => {
    const {
        children,
        size,
        textAlign,
        color,
        fontWeight,
        className,
        style: propStyle,
    } = props;

    const style = { color, textAlign, margin: 0 };

    switch (fontWeight) {
    case 'semibold':
        style.fontWeight = 500;
        break;
    default:
        style.fontWeight = fontWeight;
    }

    switch (size) {
    case 'xxs':
        style.fontSize = '0.75rem';
        break;
    case 'xs':
        style.fontSize = '0.9rem';
        break;
    case 'sm':
        style.fontSize = '1rem';
        break;
    case 'md':
        style.fontSize = '1.3rem';
        break;
    case 'l':
        style.fontSize = '2rem';
        break;
    case 'xl':
        style.fontSize = '3rem';
        break;
    default:
        console.error(`Invalid size property "${size}" provided to component`);
        return null;
    }

    return <p className={className} style={{ ...style, ...propStyle }}>{ children }</p>;
};

BaseParagraph.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    size: PropTypes.oneOf([
        'xxs', 'xs', 'sm', 'md', 'l', 'xl',
    ]),
    textAlign: PropTypes.oneOf([
        'unset', 'left', 'right', 'center', 'justify',
    ]),
    color: PropTypes.string,
    fontWeight: PropTypes.oneOf([
        'light', 'medium', 'semibold', 'bold',
    ]),
    className: PropTypes.string,
    style: PropTypes.any,
};

BaseParagraph.defaultProps = {
    size: 'sm',
    textAlign: 'unset',
    color: 'black',
    fontWeight: 'medium',
};

export default BaseParagraph;
