import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

import './style.css';

const Loader = (props) => {
    const {
        icon,
        fullscreen,
        children,
    } = props;

    let className = 'loader-container';

    if (fullscreen) {
        className = `${className} fullscreen`;
    }

    const renderSpinner = () => {
        if (icon) {
            return (
                <img src={icon} className="loader-icon" alt="loader" />
            );
        }

        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    };

    return (
        <div className={className}>
            <div className="loader-center">
                { renderSpinner() }
                { children || <p>Cargando...</p> }
            </div>
        </div>
    );
};

Loader.propTypes = {
    fullscreen: PropTypes.bool,
    icon: PropTypes.string,
    children: PropTypes.node,
};

Loader.defaultProps = {
    fullscreen: false,
};

export default Loader;
