import PropTypes from 'prop-types';

import './style.css';

const TabController = (props) => {
    const { children } = props;
    return (
        <div className="tab-bar-controller">
            { children }
            <div />
        </div>
    );
};

const TabControllerTab = (props) => {
    const { isActive, children, value, onClick } = props;

    const className = `tab-bar-controller-tab ${isActive ? 'active' : ''}`;

    return (
        <button type="button" className={className} onClick={() => onClick(value) }>
            { children }
            { isActive && <div />}
        </button>
    );
};

TabControllerTab.propTypes = {
    children: PropTypes.node,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
};

TabController.defaultProps = {
    isActive: false,
    onClick: () => {},
};

TabController.Tab = TabControllerTab;

TabController.propTypes = {
    children: PropTypes.node,
};

export default TabController;
