import AccordionComponent from '../../components/accordion';
import PropTypes from 'prop-types';

import GreenTicket from '../../assets/icons/green-ticket.svg';
import YellowWarning from '../../assets/icons/yellow-warning.svg';
import LinkIcon from '../../assets/icons/external-link.svg';

import './style.css';
import Button from '../../components/button';

import { Form } from 'react-bootstrap';
import Loader from '../../components/loader';
import { useState } from 'react';
import { useEffect } from 'react';

const TITLES = {
    DRIVER_LIFE_SHEET: 'Hoja de Vida Conductor',
    DRIVER_LICENSE_FRONT: 'Licencia de Conducir (Frontal)',
    DRIVER_LICENSE_BACK: 'Licencia de Conducir (Trasera)',
} ;

const renderAccordionItemTitle = (title, isChecked, status, num) => {
    let icon;

    if (isChecked) {
        icon = (
            <img
                src={ status === 'REJECTED' ? YellowWarning : GreenTicket }
                style={{ width: '20px', height:'20px' }}
                alt="done"
            />
        );
    } else {
        icon = (
            <div className="circular-number">
                {num}
            </div>
        );
    }

    return (
        <>
            { icon }
            <span style={{ marginLeft: '11px' }} className="font-weight-bold">
                {title}
            </span>
        </>
    );
};

const DriverStepDocs = (props) => {
    const { driverDocumentState, handleNewFile } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [activeKey, setActiveKey] = useState('0');

    useEffect(() => {
        const actKey = driverDocumentState.findIndex(document => !document.uploaded);

        setActiveKey(actKey == -1 ? null : actKey.toString());
    }, [driverDocumentState]);

    const handleNewFileWrapper = async (event, doc) => {
        setIsLoading(true);
        await handleNewFile(event, doc, 'onboardingDriverDocuments');
        setIsLoading(false);
    };
    const renderCheckMark = (isChecked) => {
        return (
            <>
                {isChecked ?
                    <img src={ GreenTicket } className="green-ticket" alt="done"></img>
                    :
                    <></>
                }
            </>
        );
    };

    const renderAccordionItemContent = (subTitle, isChecked, documentId = '', doc, renderTicket = false, renderCamera = false) => {
        if (isChecked) return <></>;
        return (
            <>
                <span className="acc-subtitle">{renderTicket ? renderCheckMark(doc.uploaded) : ''}{subTitle}</span>
                {isLoading ?
                    <Loader/> :
                    <div className="buttons-container">
                        <Form.Control type="file" id={documentId} hidden onChange={(e) => { handleNewFileWrapper(e, doc); }} />
                        <Button block={true} variant="secondary" onClick={(ev) => {document.getElementById(documentId).click();}}>{doc.uploaded ? 'Subir otra versión' : <>{renderCamera ? 'Adjuntar o tomar fotografia' : 'Adjuntar documento'}</> }</Button>
                    </div>}
            </>
        );
    };
    const getSubtitle = (index) => {
        switch (index) {
        case 0:
            return renderLinkContent('Puedes descargarlo en la sección ', ' Certificados en línea', 'https://www.registrocivil.cl/principal/servicios-en-linea');
        default:
            return '';

        }
    };
    const renderLinkContent = (preText, text, url) => {
        return (
            <div className="cc-link-content">
                {preText}
                <a className="cc-link"  target={'_blank'} href={url} rel="noreferrer">{text}</a>&nbsp;
                <a className="cc-link"  target={'_blank'} href={url} rel="noreferrer"><img alt={'link'} src={LinkIcon}/></a>
            </div>
        );
    };
    const createNeededDocsArray = () => {
        const pictureAllowedDocs = ['DRIVER_LICENSE_FRONT', 'DRIVER_LICENSE_BACK'];
        return driverDocumentState.map((doc, index) => {
            return {
                title: renderAccordionItemTitle(TITLES[doc.documentId], doc.uploaded, doc.status, index + 1),
                content: renderAccordionItemContent(getSubtitle(index), false, doc.documentId, doc, false, pictureAllowedDocs.includes(doc.documentId)),
            };
        });
    };

    return (
        <>
            <div className="onboarding-documents__step">
                <AccordionComponent activeKey={activeKey}>
                    {createNeededDocsArray()}
                </AccordionComponent>
            </div>
        </>
    );
};

DriverStepDocs.propTypes = {
    driverDocumentState: PropTypes.any,
    handleNewFile: PropTypes.func,
};

export default DriverStepDocs;