import AccordionComponent from '../../components/accordion';
import PropTypes from 'prop-types';

import GreenTicket from '../../assets/icons/green-ticket.svg';
import YellowWarning from '../../assets/icons/yellow-warning.svg';

import './style.css';
import Button from '../../components/button';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Loader from '../../components/loader';


const TITLES = {
    AFP_CERTIFICATE: 'Últimas 12 cotizaciones AFP',
    LAST_THREE_INCOME: 'Últimas 3 liquidaciones de sueldo',
    WORK_SENIORITY_CERTIFICATE: 'Contrato laboral o certificado de antiguedad',
};

const renderAccordionItemTitle = (title, uploaded, status, num) => {
    let icon;

    if (uploaded) {
        icon = (
            <img
                src={ status === 'REJECTED' ? YellowWarning : GreenTicket }
                style={{ width: '20px', height:'20px' }}
                alt="done"
            />
        );
    } else {
        icon = (
            <div className="circular-number">
                {num}
            </div>
        );
    }

    return (
        <>
            { icon }
            <span style={{ marginLeft: '11px' }} className="font-weight-bold">
                {title}
            </span>
        </>
    );
};

const WorkStepDocs = (props) => {
    const { workDocumentState, handleNewFile } = props;
    const [isLoading, setIsLoading] = useState([false, false, false]);
    const [activeKey, setActiveKey] = useState('0');

    useEffect(() => {
        let actKey = workDocumentState.findIndex(document => !document.uploaded);

        if (actKey === -1) {
            actKey = null;
        } else if ([1, 2, 3].includes(actKey)) {
            actKey = '1';
        } else if (actKey === 4) {
            actKey = '2';
        } else {
            actKey = actKey.toString();
        }

        setActiveKey(actKey);
    }, [workDocumentState]);

    const handleNewFileWrapper = async (event, doc, loadNumber) => {
        const newIsLoading = [...isLoading];
        newIsLoading[loadNumber] = true;
        setIsLoading(newIsLoading);
        await handleNewFile(event, doc, 'onboardingWorkDocuments');
        setIsLoading([false, false, false]);
    };

    const renderCheckMark = (uploaded, status) => {
        if (uploaded && status === 'REJECTED') {
            return <img src={ YellowWarning } className="green-ticket" alt="done" />;
        } else if (uploaded) {
            return <img src={ GreenTicket } className="green-ticket" alt="done" />;
        }

        return <img src={ GreenTicket } className="gray-ticket" alt="done" />;
    };

    const renderAccordionItemContent = (subtitle, isChecked, documentId = '', doc, renderTicket = false, renderCamera = false, loadNumber) => {
        if (isChecked) return null;
        return (
            <>
                <span className="acc-subtitle-centered">
                    {renderTicket ? renderCheckMark(doc.uploaded, doc.status) : ''}{subtitle}
                </span>
                {isLoading[loadNumber] ?
                    <Loader/> :
                    <div className="buttons-container">
                        <Form.Control type="file" id={documentId} hidden onChange={(e) => { handleNewFileWrapper(e, doc, loadNumber); }} />
                        <Button block={true} variant="secondary" onClick={(ev) => {document.getElementById(documentId).click();}}>{doc.uploaded ? 'Subir otra versión' : <>{renderCamera ? 'Adjuntar o tomar fotografia' : 'Adjuntar documento'}</>}</Button>
                    </div>}
            </>
        );
    };

    const createNeededDocsArray = () => {
        const incomeDocuments = [
            workDocumentState[1],
            workDocumentState[2],
            workDocumentState[3],
        ];

        const incomeUploadedStatus = incomeDocuments.every((document) => document.uploaded);

        const incomeAcceptedStatus = incomeDocuments.some((document) => document.status === 'REJECTED') ? 'REJECTED' : 'APPROVED';

        return [
            {
                title: renderAccordionItemTitle(TITLES[workDocumentState[0].documentId], workDocumentState[0].uploaded, workDocumentState[0].status, 1),
                content: renderAccordionItemContent('El documento lo puedes obtener directamente en el sitio web de tu AFP.', false, workDocumentState[0].documentId, workDocumentState[0], false, false),
            },
            {
                title: renderAccordionItemTitle(TITLES['LAST_THREE_INCOME'], incomeUploadedStatus, incomeAcceptedStatus, 2),
                content: <>
                    <div className="mb-3" style={{ fontSize: '14px' }}>
                        Solicítalas a tu empleador o en algunos casos las envían todos los meses automáticamente a tu correo.
                    </div>
                    {
                        workDocumentState.slice(1, 4).map((doc, i) => {
                            return renderAccordionItemContent(`Liquidación ${i + 1}`, false, doc.documentId, doc, true, false, i);
                        })
                    }
                </>,
            },
            {
                title: renderAccordionItemTitle(TITLES[workDocumentState[4].documentId], workDocumentState[4].uploaded, workDocumentState[4].status, 3),
                content: renderAccordionItemContent(<div style={{ display:'block', width: '100%' }}>Algún documento o certificado que acredite tu antiguedad laboral.</div>, false, workDocumentState[4].documentId, workDocumentState[4], false, false),
            },
        ];
    };

    return (
        <>
            <div className="onboarding-documents__step">
                <AccordionComponent activeKey={activeKey}>
                    {createNeededDocsArray()}
                </AccordionComponent>
            </div>
        </>
    );
};

WorkStepDocs.propTypes = {
    workDocumentState: PropTypes.any,
    handleNewFile: PropTypes.func,
};


export default WorkStepDocs;