import React from 'react';

import ProgressBar from 'react-bootstrap/ProgressBar';

import './style.css';

const Component = (props) => {
    return (
        <ProgressBar
            { ...props }
            className={'connect-car-progress ' + props.className }
        />
    );
};

Component.propTypes = {
    ...ProgressBar.propTypes,
};

export default Component;
