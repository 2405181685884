import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

export const useQueryAsObject = () => {
    const { search } = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(search);

        const data = {};

        params.forEach((value, key) => {
            data[key] = value;
        });

        return data;
    }, [search]);
};
