import useAvailableVehicle from '../../hooks/use_available_vehicle';
import { useQuery } from '../../hooks/use_query';

import OnboardingPage from './index';

const Page = () => {
    const query = useQuery();

    const brand = query.get('brand');
    const model = query.get('model');
    const version = query.get('version');
    const year = query.get('year');
    const status = query.get('status');

    const periodsAmount = query.get('periodsAmount');
    const periodType = query.get('periodType');

    const [availableVehicle] = useAvailableVehicle(brand, model, version, year, status);
    const vehicleId = availableVehicle?.id;

    return (
        <OnboardingPage
            vehicleId={vehicleId}
            periodsAmount={periodsAmount}
            periodType={periodType}
        />
    );
};

export default Page;
