import {
    camelKeys,
    toCamelCase,
    snakeKeys,
    toSnakeCase,
    kebabKeys,
    toKebabCase,
} from 'js-convert-case';
import { isArray } from 'lodash';

export { toCamelCase, toSnakeCase, toKebabCase };

export const toCamelKeys = (data) => {
    if (isArray(data)) {
        return data.map(toCamelKeys);
    }

    return camelKeys(data);
};

export const toSnakeKeys = (data) => {
    if (isArray(data)) {
        return data.map(toSnakeKeys);
    }

    return snakeKeys(data);
};

export const toKebabKeys = (data) => {
    if (isArray(data)) {
        return data.map(toKebabKeys);
    }

    return kebabKeys(data);
};
