import _ from 'lodash';
import PropTypes from 'prop-types';

import './style.css';

const merge = (...classes) => {
    return classes.filter(item => !_.isEmpty(item)).join(' ');
};

const StyledTable = (props) => {
    return <table {...props} className={merge(props.className, 'table-cc')} />;
};

StyledTable.propTypes = {
    className: PropTypes.string,
};

export default StyledTable;
