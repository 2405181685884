import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Loader from '../../components/loader';
import MainIcon from '../../assets/icons/connect-car-icon.svg';

export default function Page() {
    const [searchParams] = useSearchParams();
    const userEmail = searchParams.get('userEmail');

    useEffect(() => {
        window.intercomSettings = { ...window.intercomSettings, email: userEmail };
    }, [userEmail]);

    return (
        <div className="vh-100 d-flex align-items-center justify-content-center">
            <Loader icon={MainIcon} />
        </div>
    );
}