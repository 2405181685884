import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import apiService from '../../services/api_service';
import Title from '../../components/title';
import Paragraph from '../../components/paragraph';

import { togglePasswordField } from './helpers';

import StepSuccess from './step_success';

import './style.css';
import _ from 'lodash';

const Step = (props) => {
    const { onNext } = props;

    const [formState, setFormState] = useState({});
    const [error, setError] = useState(undefined);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(false);

    useEffect(() => {
        const { email, password } = formState;

        const isDisabled = isLoading || _.isEmpty(email) || _.isEmpty(password);

        setDisabled(isDisabled);

    }, [isLoading, formState]);

    const handleFieldChange = (event) => {
        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value,
        });

        setError(undefined);
    };

    const loginUser = async (payload) => {
        const { email, password } = payload;

        try {
            await apiService.login(email, password);

            return null;
        } catch (error) {
            if (error.message.includes('Invalid credentials provided')) {
                return 'Tu usuario y/o contraseña no son correctos.';
            }

            return 'Ocurrió un error inesperado.';
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        const error = await loginUser(formState);
        if (error !== null) {
            setError(error);
        } else {
            setShowSuccess(true);
            onNext();
        }

        setIsLoading(false);
    };

    const renderSuccess = () => {
        return (
            <StepSuccess
                title="Te estamos redirigiendo..."
            />
        );
    };

    if (showSuccess) {
        return renderSuccess();
    }

    return (
        <>
            <Title textAlign="center" size="sm">
                Bienvenido a tu sesión privada
            </Title>
            <br/>
            <form className="auth-form" onSubmit={handleSubmit}>
                <div className="auth-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="hola@connectcar.cl"
                        defaultValue={formState.email}
                        onChange={handleFieldChange}
                    />
                </div>
                <div className="auth-form-group">
                    <label htmlFor="email">Contraseña</label>
                    <div className="input-button">
                        <input
                            id="password"
                            type="password"
                            name="password"
                            placeholder="**********"
                            defaultValue={formState.password}
                            onChange={handleFieldChange}
                        />
                        <button type="button" onClick={() => togglePasswordField('password')}></button>
                    </div>
                </div>
                <div className="auth-form-links">
                    <Link to="/auth/request">
                        ¿Olvidaste tu contraseña?
                    </Link>
                </div>
                <br/>
                <br/>
                <button type="submit" disabled={isDisabled}>
                    { isLoading ? 'Iniciando sesión...' : 'Ingresar' }
                </button>
                {
                    error && <Paragraph className="mt-3" variant="error" fontSize="xs">{ error }</Paragraph>
                }
            </form>
        </>
    );
};

Step.propTypes = {
    onNext: PropTypes.func.isRequired,
};

export default Step;
