import React from 'react';
import PropTypes from 'prop-types';

import BaseParagraph from './base_components/paragraph';

const Paragraph = (props) => {
    const { variant } = props;

    let color;

    switch (variant) {
    case 'primary':
        color = '#2D2D2D';
        break;
    case 'secondary':
        color = 'white';
        break;
    case 'primaryNewTheme':
        color = '#3330E8';
        break;
    case 'secondaryNewTheme':
        color = '#131353';
        break;
    case 'link':
        color = '#003CA6';
        break;
    case 'error':
        color = '#FF0000';
        break;
    default:
        console.error(`Invalid variant property "${variant}" provided to component`);
        return null;
    }

    return <BaseParagraph {...props} color={color} />;
};

Paragraph.propTypes = {
    ...BaseParagraph.propTypes,
    variant: PropTypes.oneOf([
        'primary', 'secondary', 'primaryNewTheme', 'secondaryNewTheme', 'link', 'error',
    ]),
};

Paragraph.defaultProps = {
    ...BaseParagraph.defaultProps,
    variant: 'primary',
    fontWeight: 'light',
};

const Primary = (props) => {
    const { children, fontWeight } = props;

    return <span style={{ color: '#3330E8', fontWeight }}>{ children }</span>;
};

Primary.propTypes = {
    children: PropTypes.node,
    fontWeight: BaseParagraph.propTypes.fontWeight,
};

Paragraph.Primary = Primary;

const Secondary = (props) => {
    const { children, fontWeight } = props;

    return <span style={{ color: '#00D880', fontWeight }}>{ children }</span>;
};

Secondary.propTypes = {
    children: PropTypes.node,
    fontWeight: BaseParagraph.propTypes.fontWeight,
};

Paragraph.Secondary = Secondary;

export default Paragraph;
