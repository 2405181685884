import PropTypes from 'prop-types';
import _ from 'lodash';

import Paragraph from './paragraph';

const VehicleDetail = (props) => {
    const { vehicle, vehiclePricing } = props;

    const renderPeriodType = (periodsAmount, periodType) => {
        if (periodType === 'DAY' && periodsAmount === 1) {
            return `${periodsAmount} día`;
        }

        if (periodType === 'DAY' && periodsAmount > 1) {
            return `${periodsAmount} días`;
        }

        if (periodType === 'MONTH' && periodsAmount === 1) {
            return `${periodsAmount} mes`;
        }

        if (periodType === 'MONTH' && periodsAmount > 1) {
            return `${periodsAmount} meses`;
        }

        if (periodType === 'YEAR' && periodsAmount === 1) {
            return `${periodsAmount} año`;
        }

        if (periodType === 'YEAR' && periodsAmount > 1) {
            return `${periodsAmount} años`;
        }

        return '-';
    };

    const renderPrice = (periodPrice) => {
        if (!_.isNumber(periodPrice)) return '-';

        return `$${periodPrice.toLocaleString().replace(/,/g, '.')}`;
    };

    const renderAnnualMileage = (annualMileage, mileageUnitMeasure) => {
        return `${Intl.NumberFormat('de-DE').format(annualMileage)} ${mileageUnitMeasure}`;

    };

    const renderPeriodTitle = (periodType) => {
        if (periodType === 'DAY') {
            return 'Cuota Diaria';
        }

        if (periodType === 'MONTH') {
            return 'Cuota Mensual';
        }

        if (periodType === 'YEAR') {
            return 'Cuota Anual';
        }

        return 'Cuota por periodo';
    };

    return (
        <>
            <div className="mt-2 mb-3 d-flex justify-content-between">
                <Paragraph>
                    Condición
                </Paragraph>
                <Paragraph fontWeight="bold" textAlign="right">
                    { vehicle?.status === 'NEW' ? 'Nuevo' : 'Usado' }
                </Paragraph>
            </div>
            <div className="mb-3 d-flex justify-content-between">
                <Paragraph>
                    Período de Suscripción
                </Paragraph>
                <Paragraph fontWeight="bold" textAlign="right">
                    { renderPeriodType(vehiclePricing?.periodsAmount, vehiclePricing?.periodType) }
                </Paragraph>
            </div>
            {
                vehiclePricing?.prepaidAmount && (
                    <div className="mb-3 d-flex justify-content-between">
                        <Paragraph>
                            Prepago único
                        </Paragraph>
                        <Paragraph fontWeight="bold" textAlign="right" variant="link">
                            { renderPrice(vehiclePricing?.prepaidAmount) }
                        </Paragraph>
                    </div>
                )
            }
            <div className="mb-3 d-flex justify-content-between">
                <Paragraph>
                    { renderPeriodTitle(vehiclePricing?.periodType)}
                </Paragraph>
                <Paragraph fontWeight="bold" textAlign="right" variant="link">
                    { renderPrice(vehiclePricing?.periodPrice) }
                </Paragraph>
            </div>
            <div className="mb-3 d-flex justify-content-between">
                <Paragraph>
                    <div>Kilometraje anual</div>
                </Paragraph>
                <Paragraph fontWeight="bold" textAlign="right" variant="link">
                    { renderAnnualMileage(vehiclePricing?.annualMileage, vehiclePricing?.mileageUnitMeasure) }
                </Paragraph>
            </div>
        </>
    );
};

VehicleDetail.propTypes = {
    vehicle: PropTypes.shape({
        status: PropTypes.string,
    }),
    vehiclePricing: PropTypes.shape({
        periodsAmount: PropTypes.number,
        periodType: PropTypes.string,
        periodPrice: PropTypes.number,
        prepaidAmount: PropTypes.number,
        annualMileage: PropTypes.number,
        mileageUnitMeasure: PropTypes.oneOf(['km', 'mi']),
    }),
};

export default VehicleDetail;
