import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';

import Title from '../../components/title';
import ProgressStepper from '../../components/progress_stepper';
import Button from '../../components/button';

import Paragraph from '../../components/paragraph';
import BaseCard from '../../components/base_components/card';
import EmptyState from '../../components/empty_state';
import Loader from '../../components/loader';

import PersonalStepDocs from './step_personal_doc';
import WorkStepDocs from './step_work_doc';
import DriverStepDocs from './step_driver_doc';
import apiService from '../../services/api_service';
import sessionService from '../../services/session_service';
import _ from 'lodash';
import ValidationResult from './step_validation_result';

import MainIcon from '../../assets/icons/connect-car-icon.svg';
import MissingKeys from '../../assets/images/missing-keys.jpg';

import { useQuery } from '../../hooks/use_query';

const ONBOARDING_STEPS = [
    'Personal',
    'Laboral',
    'Conductor',
];

const INITIAL_STEP = 0;

const OnboardingDocumentsPage = () => {
    const query = useQuery();
    const navigate = useNavigate();

    const loggedIn = sessionService.useLoggedIn();
    let userId = sessionService.useSessionUserId();
    const queryUserId = query.get('userId') ?? null;

    if (_.isNull(userId)) {
        userId = queryUserId;
    }

    const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
    const [personalDocuments, setPersonalDocuments] = useState(undefined);
    const [workDocuments, setWorkDocuments] = useState(undefined);
    const [driverDocuments, setDriverDocuments] = useState(undefined);
    const [shouldAlertShow, setShouldAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('success');


    const getDocumentUploadStatus = async (endpoint, setter) =>{
        const documents = await apiService.findAll(endpoint, { userId: userId });
        if (!_.has(documents, 'data')) return;
        const { data } = documents;
        setter(data);
    };

    const performExitRedirect = () => {
        if (loggedIn) {
            navigate('/');
        } else {
            window.location.replace('https://www.connectcar.cl');
        }
    };

    const getAllDocumentUploadStatus = async () => {
        try {
            await getDocumentUploadStatus('onboardingPersonalDocuments', setPersonalDocuments);
            await getDocumentUploadStatus('onboardingWorkDocuments', setWorkDocuments);
            await getDocumentUploadStatus('onboardingDriverDocuments', setDriverDocuments);
        } catch (err) {
            performExitRedirect();
        }
    };

    useEffect(() => {
        if (_.isNull(userId)) return;

        if (!_.isNull(queryUserId) && userId !== queryUserId) {
            performExitRedirect();
        } else {
            getAllDocumentUploadStatus();
        }
    }, [userId]);

    const getPresignedUrl = async (documentType) => {
        const response = await apiService.post('documentFileUploadUrl', { userId: userId, documentId: documentType });
        if (!_.has(response, 'data')) return null;
        const { url } = response.data;
        return url;
    };

    const handleNewFile = async (event, doc, typeOfDoc) => {
        event.preventDefault();
        const { target } = event;
        const { files } = target;

        target.disabled = true;

        if (files.length === 0) return;

        const file = files[0];

        try {
            const url = await getPresignedUrl(doc.documentId);

            if (_.isNull(url)) {
                console.error('No se ha podido obtener la url para subir el archivo');
                return;
            }
            const response = await fetch(url, {
                method: 'PUT',
                body: file,
            });

            target.disabled = false;

            if (response.status !== 200) {
                console.error('Error uploading file');
                showAlert(false);
            } else {
                const payload = {
                    userId: userId,
                    documentId: doc.documentId,
                };

                await apiService.post(typeOfDoc, payload);

                await getAllDocumentUploadStatus();
                showAlert(true);
            }
            return;
        } catch (err) {
            console.log(err);
            showAlert(false);
        }

    };

    const showAlert = (success) =>{
        if (success) {
            setAlertMessage('Documento subido correctamente.');
            setAlertVariant('success');
        }
        else {
            setAlertMessage('No se ha podido cargar el documento. Por favor, inténtelo nuevamente.');
            setAlertVariant('danger');
        }
        setShouldAlertShow(true);
        setTimeout(() => {
            setShouldAlertShow(false);
        }, 4000);
    };

    const renderErrorAlert = () => {
        return (
            <Alert key="light" variant= {alertVariant} className={shouldAlertShow ? 'showAlert' : 'hideAlert'}>
                <Paragraph>
                    {alertMessage}
                </Paragraph>
            </Alert>
        );
    };

    const renderProgressStepper = (step) => {
        return (

            <Row className="mb-4">
                <Col>
                    <ProgressStepper steps={ONBOARDING_STEPS} currentStep={currentStep} />
                </Col>
            </Row>
        );
    };

    const renderImportantDialog =  ( ) => {
        if (currentStep >= 3) return;
        return <>
            <Row>
                <Paragraph textAlign="center" size="sm">
                    Adjunta los documentos solicitados para continuar con el proceso.
                </Paragraph>
            </Row>
            <Row>
                <Col>
                    <BaseCard>
                        Si no tienes todos los documentos en este momento, puedes continuar el proceso cuando los tengas disponibles.
                    </BaseCard>
                </Col>
            </Row>
        </>;
    };

    const getCurrentStepDocuments = (step) => {
        switch (step) {
        case 0:
            return personalDocuments;
        case 1:
            return workDocuments;
        case 2:
            return driverDocuments;
        default:
            return [];
        }
    };

    const renderOnboardingStep = (step) => {
        if (step === 1) {
            return (
                <WorkStepDocs handleNewFile={handleNewFile} workDocumentState={workDocuments}/>
            );
        }

        if (step === 2) {
            return (
                <DriverStepDocs handleNewFile={handleNewFile} driverDocumentState={driverDocuments} />
            );
        }

        if (step === 0) {
            return (
                <PersonalStepDocs handleNewFile={handleNewFile} personalDocumentState={personalDocuments} />
            );
        }

        return (
            <ValidationResult onFinishUrl={loggedIn ? '/' : 'https://www.connectcar.cl'} />
        );
    };

    const renderNextButton = () => {
        if (currentStep >= 3) return;

        let backButton;

        if (loggedIn) {
            backButton = (
                <Button onClick={() => navigate(-1)} block variant="link">
                    Terminar en otro momento
                </Button>
            );
        }

        return (
            <div className="mb-5 mt-5">
                <Button
                    disabled={!getCurrentStepDocuments(currentStep).every( doc => doc.uploaded)}
                    variant="primary"
                    block
                    onClick={() => setCurrentStep(currentStep + 1) }
                >
                    {currentStep === 2 ? 'Finalizar' : 'Siguiente'}
                </Button>
                { backButton }
            </div>
        );
    };

    if (_.isNull(userId)) {
        return (
            <Container>
                <Row>
                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                        <br />
                        <br />
                        <br />
                        <EmptyState
                            title="Algo no ha andado bien"
                            subtitle="No hemos encontrado tu usuario"
                            src={MissingKeys}
                        >
                            <br />
                            <Button href="https://www.connectcar.cl" variant="primary">
                                Volver a connect car
                            </Button>
                        </EmptyState>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (
        _.isUndefined(userId)
        || _.isUndefined(workDocuments)
        || _.isUndefined(driverDocuments)
        || _.isUndefined(personalDocuments)
    ) {
        return (
            <>
                <Loader icon={MainIcon} fullscreen>
                    <Paragraph>
                        Estamos cargando tus datos...
                    </Paragraph>
                </Loader>
            </>
        );
    }

    return (
        <Container>
            <Row>
                <Col xs={12} md={{ span: 6, offset: 3 }}>
                    <Row className="my-3">
                        <Col>
                            <Title textAlign="center" size="md" variant="secondaryNewTheme">
                                Solicitud documentos
                            </Title>
                        </Col>
                    </Row>
                    { renderProgressStepper(currentStep) }
                    {renderImportantDialog()}
                    <Row style={{ marginTop: '28px' }}>
                        <Col>
                            { renderOnboardingStep(currentStep) }
                        </Col>
                    </Row>
                    {renderErrorAlert()}
                    <Row>
                        <Col>
                            { renderNextButton() }
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
    );
};

export default OnboardingDocumentsPage;
