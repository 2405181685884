import { useState, useEffect } from 'react';
import _ from 'lodash';

export default (initialState) => {
    const [formState, setFormState] = useState(initialState);

    useEffect(() => {
        try {
            const data = localStorage.getItem('formState');
            const jsonFormState = JSON.parse(data);

            if (_.isEmpty(jsonFormState)) {
                throw new Error('Invalid local form state');
            }

            setFormState(jsonFormState);
        } catch (error) {
            setFormState(initialState);
        }
    }, []);

    return [formState, setFormState];
};
