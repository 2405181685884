import _ from 'lodash';

export const NotificationService = (props) => {
    const { event } = props || {};

    let eventName = event;
    if (_.isEmpty(event)) {
        eventName = '__notification_sent';
    }

    const on = (callback) => {
        document.addEventListener(eventName, (e) => callback(e.detail));
    };

    const dispatch = (message, level = 'danger') => {
        document.dispatchEvent(new CustomEvent(eventName, { detail: { message, level } }));
    };

    const remove = (callback) => {
        document.removeEventListener(eventName, callback);
    };

    return { on, dispatch, remove };
};
