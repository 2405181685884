import React from 'react';
import _ from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useSessionUser from '../../hooks/use_session_user';
import useSubscriptions from '../../hooks/use_subscriptions';

import Title from '../../components/title';
import Loader from '../../components/loader';
import SubscriptionCard from '../../components/subscription_card';
import QuickAccessButtons from '../../components/quick_access_buttons';

import MainIcon from '../../assets/icons/connect-car-icon.svg';
import { ACTIVE_STATUSES, PENDING_STATUSES } from '../../constants/subscription_status';

const Page = () => {
    const user = useSessionUser();

    const [subscriptions] = useSubscriptions();

    if (_.isUndefined(user)) {
        return (
            <div style={{ marginTop: '5rem' }}>
                <Loader icon={MainIcon} />
            </div>
        );
    }

    if (_.isEmpty(user)) {
        return 'No encontramos tu usuario!';
    }

    const renderSubscriptions = (title, subscriptions) => {
        if (_.isEmpty(subscriptions) || !_.isArray(subscriptions)) return null;

        return (
            <Row>
                <Col>
                    <br/>
                    <br/>
                    <Title size="sm" variant="primaryNewTheme">
                        { title }
                    </Title>
                    <br/>
                    <Row>
                        { subscriptions.map((subscription, index) => {
                            return (
                                <Col key={subscription.id} xl={4} className="mb-3">
                                    <SubscriptionCard subscription={subscription} index={index} />
                                </Col>
                            );
                        }) }
                    </Row>
                </Col>
            </Row>
        );
    };

    const renderSubscriptionsBlock = (subscriptions) => {
        if (_.isUndefined(subscriptions)) {
            return (
                <>
                    <br />
                    <br />
                    <Loader icon={MainIcon}>
                        <p>Cargando tus suscripciones...</p>
                    </Loader>
                </>
            );
        }

        const activeSubscriptions = (subscriptions ?? []).filter(subscription => ACTIVE_STATUSES.includes(subscription.status));

        const pendingSubscriptions = (subscriptions ?? []).filter(subscription => PENDING_STATUSES.includes(subscription.status));

        if (_.isEmpty(activeSubscriptions) && _.isEmpty(pendingSubscriptions)) return null;

        return (
            <>
                { renderSubscriptions('Tus suscripciones activas', activeSubscriptions) }
                { renderSubscriptions('Tus suscripciones en proceso', pendingSubscriptions) }
            </>
        );
    };

    const renderSelfHelpBlock = () => {
        return (
            <Row>
                <Col>
                    <br/>
                    <br/>
                    <Title size="sm" variant="primaryNewTheme">
                        ¿Qué necesitas hacer en tu vehículo?
                    </Title>
                    <br/>
                    <Row>
                        <Col>
                            <QuickAccessButtons
                                thefts
                                collisions
                                assistance
                                whatsapp
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Title variant="secondaryNewTheme">
                Mi sesión connect car
            </Title>
            { renderSelfHelpBlock() }
            { renderSubscriptionsBlock(subscriptions) }
        </>
    );
};

export default Page;
