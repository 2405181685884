import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '../../components/button';
import Loader from '../../components/loader';
import ImageCard from '../image_card';
import Paragraph from '../paragraph';

import useVehicle from '../../hooks/use_subscription_vehicle';
import useVehicleImage from '../../hooks/use_vehicle_image';
import SUBSCRIPTION_STATUS, { OTHER_STATUSES } from '../../constants/subscription_status';

import SpinnerLoader from '../../assets/images/spinner_loader.svg';
import ConnectCarIcon from '../../assets/icons/connect-car-icon-white.svg';

import MainIcon from '../../assets/icons/connect-car-icon.svg';
import PlugIcon from '../../assets/icons/plug';

import './style.css';

const Card = (props) => {
    const { variant, subscription, index } = props;

    const { id, status, periodType, currency, periodsAmount, periodPrice, createdAt, vehicleId } = subscription;

    const [vehicle] = useVehicle(vehicleId.id ?? vehicleId);

    const [vehicleImage] = useVehicleImage(vehicle?.brand, vehicle?.model, vehicle?.version, vehicle?.year, 'IMAGE_PRIMARY');

    if (_.isUndefined(vehicle)) {
        return <Loader  icon={MainIcon} />;
    }

    if (_.isEmpty(vehicle)) return null;

    const createdDate = new Date(0);
    createdDate.setSeconds(createdAt);

    const toCurrency = (currency, value) => {
        const systemLocale = undefined;

        const numberCurrency = (currency ?? 'CLP').toUpperCase();

        const formatter = new Intl.NumberFormat(systemLocale, {
            style: 'currency',
            currency: numberCurrency,
        });

        if (numberCurrency === 'CLP') {
            return '$ ' + formatter
                .format(value)
                .replace(/\./g, '|')
                .replace(/,/g, '.')
                .replace(/\|/g, ',')
                .replace(/CLP\s*/, '');
        }

        if (numberCurrency === 'CLF') {
            return formatter
                .format(value)
                .replace(/\./g, '|')
                .replace(/,/g, '.')
                .replace(/\|/g, ',')
                .replace(/CLF\s*/, '')
                .replace(/0+$/, '')
                .replace(/,$/g, '')
                + ' UF';
        }

        return formatter.format(value).replace(/\./g, '|').replace(/,/g, '.').replace(/\|/g, ',');
    };

    const toLocalePeriodType = (periodType, plural) => {
        if (periodType === 'DAY') {
            return plural ? 'días' : 'día';
        }

        if (periodType === 'WEEK') {
            return plural ? 'semanas' : 'semana';
        }

        if (periodType === 'MONTH') {
            return plural ? 'meses' : 'mes';
        }

        if (periodType === 'YEAR') {
            return plural ? 'años' : 'año';
        }

        return periodType;
    };

    const toLocalePeriodDuration = (periodType, plural) => {
        if (periodType === 'DAY') {
            return plural ? 'diarios' : 'diario';
        }

        if (periodType === 'WEEK') {
            return plural ? 'semanales' : 'semanal';
        }

        if (periodType === 'MONTH') {
            return plural ? 'mensuales' : 'mensual';
        }

        if (periodType === 'YEAR') {
            return plural ? 'anuales' : 'anual';
        }

        return periodType;
    };

    const renderButton = (status) => {
        switch (status) {
        case SUBSCRIPTION_STATUS.REVIEW_REQUIRED:
            return (
                <Button variant="primary" disabled block>
                    Esperando aprobación
                </Button>
            );

        case SUBSCRIPTION_STATUS.PREAPPROVED:
            return (
                <Button variant="primary" href={'/documents'} block>
                    Terminar proceso
                </Button>
            );

        case SUBSCRIPTION_STATUS.CONTRACT_PENDING:
        case SUBSCRIPTION_STATUS.WARRANTY_PENDING:
        case SUBSCRIPTION_STATUS.PAYMENT_PENDING:
        case SUBSCRIPTION_STATUS.SCHEDULING_PENDING:
            return (
                <Button variant="primary" href={`/subscriptions/${id}/onboarding`} block>
                    Terminar proceso
                </Button>
            );

        case SUBSCRIPTION_STATUS.DELIVERY_CERTIFICATE_PENDING:
        case SUBSCRIPTION_STATUS.DELIVERY_PENDING:
            return (
                <Button variant="primary" disabled block>
                    Esperando entrega
                </Button>
            );

        case SUBSCRIPTION_STATUS.SUSPENDED:
            return (
                <Button variant="primary" disabled block>
                    Suspendida
                </Button>
            );

        case SUBSCRIPTION_STATUS.REJECTED:
            return (
                <Button variant="primary" disabled block>
                    Rechazada
                </Button>
            );

        case SUBSCRIPTION_STATUS.ACTIVE:
        case SUBSCRIPTION_STATUS.CLOSED:
            return (
                <Button variant="primary" href={`/subscriptions/${id}`} block>
                    Ver detalle
                </Button>
            );

        case SUBSCRIPTION_STATUS.CREATED:
        default:
            return <div className="button-placeholder" />;
        }
    };

    let primaryColor = index % 2 ? '#3330E8' : '#131353';
    let secondaryColor = index % 2 ? '#131353' : '#00D880';
    let textColor = index % 2 ? 'white' : '#131353';

    if (OTHER_STATUSES.includes(status)) {
        primaryColor = '#c1c2c7';
        secondaryColor = '#dedfe4';
        textColor = 'white';
    }

    let src;
    if (_.isUndefined(vehicleImage)) {
        src = SpinnerLoader;
    } else if (!_.isUndefined(vehicleImage?.url)) {
        src = vehicleImage?.url;
    } else {
        src = '';
    }

    return (
        <ImageCard
            key={id}
            title={ vehicle.brand }
            badge={vehicle.fuel === 'ELECTRIC' && <PlugIcon style={{ width: '2rem', height: '2rem', color: 'white' }} />}
            subtitle={ vehicle.model }
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            textColor={textColor}
            src={src}
            defaultSrc={ConnectCarIcon}
            ribbon={status === 'CLOSED' && 'Finalizada'}
            imageFilter={status === 'CLOSED' && 'grayscale(1)'}
        >
            <Paragraph variant="secondary" textAlign="center" fontWeight="light" className={variant === 'full' ? 'mb-3' : ''}>
                { vehicle.version || <div>&nbsp;</div> }
            </Paragraph>
            {
                variant === 'full' && (
                    <Paragraph variant="secondary" fontWeight="bold" textAlign="center" size="md">
                        Mi suscripción
                    </Paragraph>
                )
            }
            {
                variant === 'full' && (
                    <>
                        <Row className="mt-3">
                            <Col>
                                <Paragraph variant="secondary" fontWeight="bold" textAlign="center" size="xs">
                                    18.000 kms
                                </Paragraph>
                                <Paragraph variant="secondary" textAlign="center" size="xxs">
                                    Incluidos
                                </Paragraph>
                            </Col>
                            <Col style={{ borderLeft: '1px solid white' }}>
                                <Paragraph variant="secondary" fontWeight="bold" textAlign="center" size="xs">
                                    10 UF
                                </Paragraph>
                                <Paragraph variant="secondary" textAlign="center" size="xxs">
                                    Deducible seguro
                                </Paragraph>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col>
                                <Paragraph variant="secondary" fontWeight="bold" textAlign="center" size="xs">
                                    { periodsAmount } { toLocalePeriodType(periodType, periodsAmount > 1) }
                                </Paragraph>
                                <Paragraph variant="secondary" textAlign="center" size="xxs">
                                    Periodo
                                </Paragraph>
                            </Col>
                            <Col style={{ borderLeft: '1px solid white' }}>
                                <Paragraph variant="secondary" fontWeight="bold" textAlign="center" size="xs">
                                    { toCurrency(currency, periodPrice) }
                                </Paragraph>
                                <Paragraph variant="secondary" textAlign="center" size="xxs">
                                    Cuota { toLocalePeriodDuration(periodType, periodsAmount > 1) }
                                </Paragraph>
                            </Col>
                        </Row>
                    </>
                )
            }
            { variant === 'full' && renderButton(status) }
        </ImageCard>
    );
};

Card.propTypes = {
    variant: PropTypes.oneOf([
        'full',
        'simple',
    ]),
    subscription: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
        periodType: PropTypes.string,
        periodsAmount: PropTypes.number,
        periodPrice: PropTypes.number,
        createdAt: PropTypes.number,
        vehicleId: PropTypes.string,
        currency: PropTypes.string,
    }).isRequired,
    index: PropTypes.number.isRequired,
};

Card.defaultProps = {
    variant: 'full',
};

export default Card;
