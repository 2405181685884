import _ from 'lodash';
import { useEffect, useState } from 'react';

import apiService from '../services/api_service';

export default (vehicleId, periodType, periodsAmount, annualMileage, mileageUnitMeasure) => {
    const [vehicle, setVehicle] = useState(undefined);

    const fetch = async (vehicleId, periodType, periodsAmount, cancelToken) => {
        if (_.isEmpty(vehicleId) || _.isEmpty(periodType) || !_.isNumber(periodsAmount)) return;

        const response = await apiService.findAll('vehiclePricings', { vehicleId, periodType, periodsAmount, annualMileage, mileageUnitMeasure }, cancelToken);

        if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

        if (_.isEmpty(response.data)) {
            setVehicle(null);
        } else {
            setVehicle(_.first(response.data));
        }
    };

    useEffect(() => {
        const cancelToken = apiService.start();

        fetch(vehicleId, periodType, periodsAmount, cancelToken);

        return () => apiService.cancel(cancelToken);
    }, [vehicleId]);

    return [vehicle, setVehicle];
};
