import _ from 'lodash';
import { useEffect, useState } from 'react';

import apiService from '../services/api_service';
import sessionService from '../services/session_service';

export default (query = {}) => {
    const [items, setItems] = useState(undefined);
    const userId = sessionService.useSessionUserId();

    const fetch = async (subscriptionId, cancelToken) => {
        if (_.isEmpty(subscriptionId)) return;

        setItems(undefined);

        const response = await apiService.findAll('subscriptionDocuments', { subscriptionId }, cancelToken);

        if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

        setItems(response.data);
    };

    useEffect(() => {
        const cancelToken = apiService.start();

        fetch(query.subscriptionId, cancelToken);

        return () => apiService.cancel(cancelToken);
    }, [userId]);

    return [items, setItems];
};
