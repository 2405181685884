import _ from 'lodash';
import { useEffect, useState } from 'react';

import apiService from '../services/api_service';

export const fetch = async (vehicleId, cancelToken, setVehicle) => {
    if (_.isEmpty(vehicleId)) return;

    const response = await apiService.findOne('subscriptionVehicles', vehicleId, { populate: true }, cancelToken);

    if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

    setVehicle(response.data);
};

export default (vehicleId) => {
    const [vehicle, setVehicle] = useState(undefined);

    useEffect(() => {
        const cancelToken = apiService.start();

        fetch(vehicleId, cancelToken, setVehicle);

        return () => apiService.cancel(cancelToken);
    }, [vehicleId]);

    return [vehicle, setVehicle];
};
