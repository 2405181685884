import { useState } from 'react';

import {
    useNavigate,
} from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../../components/title';
import Paragraph from '../../components/paragraph';
import Button from '../../components/button';
import TabController from '../../components/tab_controller';
import useSessionUser from '../../hooks/use_session_user';

import sessionService from '../../services/session_service';

import StepPasswordRequest from '../auth/step_password_request';

const TAB_STATES = {
    PERSONAL_INFO: 0,
    PASSWORD: 1,
};

const Page = () => {
    const navigate = useNavigate();

    const [ selectedTab, setSelectedTab ] = useState(TAB_STATES.PERSONAL_INFO);
    const user = useSessionUser();

    const renderTabBody = (selectedTab) => {
        if (selectedTab === TAB_STATES.PERSONAL_INFO) {
            return (
                <>
                    <Row>
                        <Col className="py-2" xs={3} md={6}>
                            <Paragraph variant="secondaryNewTheme">
                                Nombre
                            </Paragraph>
                        </Col>
                        <Col className="py-2">
                            <Paragraph variant="secondaryNewTheme" fontWeight="bold">
                                { user?.firstname ?? '-' }
                            </Paragraph>
                        </Col>
                    </Row>
                    <hr style={{ borderColor: '#e1e1e1', opacity: 1 }} />
                    <Row>
                        <Col className="py-2" xs={3} md={6}>
                            <Paragraph variant="secondaryNewTheme">
                                Apellido
                            </Paragraph>
                        </Col>
                        <Col className="py-2">
                            <Paragraph variant="secondaryNewTheme" fontWeight="bold">
                                { user?.lastname ?? '-' }
                            </Paragraph>
                        </Col>
                    </Row>
                    <hr style={{ borderColor: '#e1e1e1', opacity: 1 }} />
                    <Row>
                        <Col className="py-2" xs={3} md={6}>
                            <Paragraph variant="secondaryNewTheme">
                                Email
                            </Paragraph>
                        </Col>
                        <Col className="py-2">
                            <Paragraph variant="secondaryNewTheme" fontWeight="bold">
                                { user?.email ?? '-' }
                            </Paragraph>
                        </Col>
                    </Row>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <Row>
                        <Col xs={12} md={4}>
                            <Button
                                variant="primary"
                                block
                                onClick={() => sessionService.removeSession()}
                            >
                                Cerrar sesión
                            </Button>
                        </Col>
                    </Row>
                </>
            );
        }

        if (selectedTab === TAB_STATES.PASSWORD) {
            return (
                <Row>
                    <Col sm={12} md={{ span: 6 }}>
                        <StepPasswordRequest
                            variant={StepPasswordRequest.VARIANT.LOGGEDIN_REQUEST}
                            email={user?.email}
                            onNext={() => navigate('/') }
                        />
                    </Col>
                </Row>
            );
        }

        return null;
    };

    return (
        <>
            <Title variant="secondaryNewTheme">
                Mi Perfil
            </Title>
            <br/>
            <br/>
            <TabController>
                <TabController.Tab
                    value={TAB_STATES.PERSONAL_INFO}
                    onClick={setSelectedTab}
                    isActive={selectedTab === TAB_STATES.PERSONAL_INFO}
                >
                    <Paragraph
                        variant="secondaryNewTheme"
                        size="xs"
                        fontWeight={selectedTab === TAB_STATES.PERSONAL_INFO ? 'bold' : 'normal' }
                    >
                        Información Personal
                    </Paragraph>
                </TabController.Tab>
                <TabController.Tab
                    value={TAB_STATES.PASSWORD}
                    onClick={setSelectedTab}
                    isActive={selectedTab === TAB_STATES.PASSWORD}
                >
                    <Paragraph
                        variant="secondaryNewTheme"
                        size="xs"
                        fontWeight={selectedTab === TAB_STATES.PASSWORD ? 'bold' : 'normal' }
                    >
                        Contraseña
                    </Paragraph>
                </TabController.Tab>
            </TabController>
            <div className="py-5">
                { renderTabBody(selectedTab) }
            </div>
        </>
    );
};

export default Page;
