import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

import './style.css';

const BaseButton = (props) => {
    const {
        children,
        onClick,
        block,
        loading,
        size,
        href,
        fontWeight,
        backgroundColor,
        borderColor,
        textColor,
        borderRadius,
        disabled,
        target,
        rel,
    } = props;

    const style = {
        background: backgroundColor,
        borderRadius,
        border: `2px solid ${borderColor}`,
        color: textColor,
        width: block ? '100%' : 'unset',
    };

    switch (fontWeight) {
    case 'light':
        style.fontWeight = 300;
        break;

    case 'regular':
        style.fontWeight = 500;
        break;

    case 'bold':
        style.fontWeight = 800;
        break;

    default:
        break;
    }

    switch (size) {
    case 'xs':
        style.fontSize = '0.75rem !important';
        break;
    case 'sm':
        style.fontSize = '1rem !important';
        break;
    case 'md':
        style.fontSize = '1.5rem !important';
        break;
    case 'l':
        style.fontSize = '2rem !important';
        break;
    case 'xl':
        style.fontSize = '3rem !important';
        break;
    default:
        console.error(`Invalid size property "${size}" passed to component`);
        return null;
    }

    if (href) {
        return (
            <a
                className={`base-button ${disabled || loading ? 'disabled' : ''} ${props.className}`}
                style={style}
                disabled={disabled || loading}
                href={href}
                target={target}
                rel={rel}
                onClick={onClick}
            >
                { loading ? <Spinner animation="border"as="span" size="sm" /> : children }
            </a>
        );
    }

    return (
        <button
            className={`base-button ${disabled || loading ? 'disabled' : ''} ${props.className}`}
            type="button"
            style={style}
            onClick={onClick}
            disabled={disabled || loading}
        >
            { loading ? <Spinner animation="border"as="span" size="sm" /> : children }
        </button>
    );
};

BaseButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    onClick: PropTypes.func,
    block: PropTypes.bool,
    loading: PropTypes.bool,
    size: PropTypes.oneOf([
        'xs', 'sm', 'md', 'l', 'xl',
    ]),
    href: PropTypes.string,
    fontWeight: PropTypes.oneOf([
        'light', 'regular', 'bold',
    ]),
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    textColor: PropTypes.string,
    borderRadius: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
};

BaseButton.defaultProps = {
    onClick: () => {},
    block: false,
    size: 'sm',
    href: null,
    fontWeight: 'regular',
    backgroundColor: 'white',
    borderColor: 'darkblue',
    textColor: 'darkblue',
    borderRadius: '0',
    disabled: false,
    className: '',
};

export default BaseButton;
