import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import Title from '../../components/title';
import Paragraph from '../../components/paragraph';

import sessionService from '../../services/session_service';
import apiService from '../../services/api_service';
import { togglePasswordField } from './helpers';

import StepSuccess from './step_success';

import CheckCircleOutlined from '../../assets/icons/check-circle-outlined';
import TimesCircleOutlined from '../../assets/icons/times-circle-outlined';

import './style.css';

const Step = (props) => {
    const { onNext } = props;

    const [searchParams] = useSearchParams();

    const [formState, setFormState] = useState({
        userId: searchParams.get('userId'),
        token: searchParams.get('token'),
    });
    const [error, setError] = useState(undefined);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordRequirements, setPasswordRequirements] = useState({});

    useEffect(() => {
        if (_.isEmpty(formState.userId) || _.isEmpty(formState.token)) {
            onNext();
        }
    }, [formState]);

    useEffect(() => {
        if (_.isEmpty(formState.password)) {
            setPasswordRequirements({});
        } else {
            const requirements = {
                uppercase: /[A-Z]/.test(formState.password),
                lowercase: /[a-z]/.test(formState.password),
                number: /\d/.test(formState.password),
                specialChar: /[^a-zA-Z\d]/.test(formState.password),
                length: formState.password.length >= 8,
                equal: formState.password === formState.passwordRepeat,
            };

            setPasswordRequirements(requirements);
        }
    }, [formState]);

    const handleFieldChange = (event) => {
        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value,
        });

        setError(undefined);
    };

    const resetCredentials = async (payload) => {
        const { password, passwordRepeat, token, userId } = payload;

        try {
            await apiService.post('userCredentialReset', {
                step: 'RESET',
                password,
                passwordRepeat,
                token,
                userId,
            });

            sessionService.removeSession();

            return null;
        } catch (error) {
            if (error.message.includes('Invalid token provided')) {
                return 'Por razones de seguridad este link ha expirado. Debes solicitar uno nuevo para cambiar tu contraseña.';
            }

            if (error.message.includes('Password')) {
                return 'Tu contraseña no cumple los requisitos de seguridad.';
            }

            return 'Ocurrió un error inesperado.';
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        const error = await resetCredentials(formState);
        if (error !== null) {
            setError(error);
        } else {
            setShowSuccess(true);
        }

        setIsLoading(false);
    };

    const renderSuccess = () => {
        return (
            <StepSuccess
                title="Cambio de contraseña"
                subtitle="Tu contraseña se ha creado correctame.Ya puedes iniciar sesión con tu nueva contraseña."
                buttonText="Ir al login"
                onClick={onNext}
            />
        );
    };

    const renderRequirement = (complies, text) => {
        if (complies === undefined) {
            return (
                <Paragraph size="xs" className="auth-form-validator" variant="secondaryNewTheme">
                    <CheckCircleOutlined className="unset" /> {text}
                </Paragraph>
            );
        }

        if (complies) {
            return (
                <Paragraph size="xs" className="auth-form-validator" variant="secondaryNewTheme">
                    <CheckCircleOutlined className="active" /> {text}
                </Paragraph>
            );
        }

        return (
            <Paragraph size="xs" className="auth-form-validator" variant="error">
                <TimesCircleOutlined className="error" /> {text}
            </Paragraph>
        );
    };

    if (showSuccess) {
        return renderSuccess();
    }

    return (
        <>
            <Title textAlign="center" size="sm">
                Cambio de contraseña
            </Title>
            <br/>
            <Paragraph textAlign="center">
                Es necesario agregar una nueva contraseña segura para iniciar sesión nuevamente.
            </Paragraph>
            <form className="auth-form" onSubmit={handleSubmit}>
                <div className="auth-form-group">
                    <label htmlFor="email">
                        Ingresa tu nueva contraseña
                    </label>
                    <div className="input-button">
                        <input
                            id="password"
                            type="password"
                            name="password"
                            placeholder="**********"
                            defaultValue={formState.password}
                            onChange={handleFieldChange}
                            autoComplete="off"
                        />
                        <button type="button" onClick={() => togglePasswordField('password')}></button>
                    </div>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="email">
                        Confirma tu nueva contraseña
                    </label>
                    <div className="input-button">
                        <input
                            id="passwordRepeat"
                            type="password"
                            name="passwordRepeat"
                            placeholder="**********"
                            defaultValue={formState.passwordRepeat}
                            onChange={handleFieldChange}
                            autoComplete="off"
                        />
                        <button type="button" onClick={() => togglePasswordField('passwordRepeat')}></button>
                    </div>
                </div>
                <div>
                    { renderRequirement(passwordRequirements.uppercase, 'Al menos una mayúscula') }
                    { renderRequirement(passwordRequirements.lowercase, 'Al menos una minúscula') }
                    { renderRequirement(passwordRequirements.number, 'Al menos un número') }
                    { renderRequirement(passwordRequirements.specialChar, 'Al menos un caracter especial') }
                    { renderRequirement(passwordRequirements.length, 'Al menos 8 caracteres') }
                    { renderRequirement(passwordRequirements.equal, 'Las contraseñas coinciden') }
                </div>
                <br/>
                <br/>
                <button type="submit" disabled={isLoading || _.isEmpty(passwordRequirements) || Object.values(passwordRequirements).some(item => item === false)}>
                    { isLoading ? 'Actualizando tu contraseña...' : 'Crear nueva contraseña' }
                </button>
                {
                    error && <Paragraph className="mt-3" variant="error" size="xs">{ error }</Paragraph>
                }
            </form>
        </>
    );
};

Step.propTypes = {
    onNext: PropTypes.func.isRequired,
};

export default Step;
