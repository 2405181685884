import PropTypes from 'prop-types';
import _ from 'lodash';

import Paragraph from '../paragraph';

import './style.css';

const InputError = (props) => {
    const { text } = props;

    if (_.isEmpty(text)) return null;

    return (
        <Paragraph size="xs" variant="error">
            { text }
        </Paragraph>
    );
};

InputError.propTypes = {
    text: PropTypes.string,
};

const inputStyle = {
    display: 'block',
    width: '100%',
    border: '2px solid lightgray',
    padding: '1rem',
    fontSize: '1rem',
};

const Input = (props) => {
    const { error, type, placeholder, ...nextProps } = props;

    if (type === 'checkbox') {
        return (
            <>
                <label className="cc-checkbox-label">
                    <input type={type} {...nextProps} className={`${props.className} cc-checkbox-input`} />
                    { placeholder }
                </label>
                <InputError text={error} />
            </>
        );
    }

    return (
        <>
            <input style={inputStyle} type={type} placeholder={placeholder} {...nextProps} />

            <InputError text={error} />
        </>
    );
};

Input.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    type: PropTypes.string,
    error: PropTypes.string,
};

export default Input;
