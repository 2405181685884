import { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';

import Menu from '../../components/menu';

import useSessionUser from '../../hooks/use_session_user';

import MainLogo from '../../assets/icons/connect-car-logo.svg';
import HouseIcon from '../../assets/icons/house';
import CarIcon from '../../assets/icons/car';
import UserIcon from '../../assets/icons/user';
import Clipboard from '../../assets/icons/clipboard';

import './style.css';

const Layout = (props) => {
    const { view } = props;
    const user = useSessionUser();

    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const { pathname } = window.location;

    const renderButtons = () => {
        const buttons = [
            {
                title: 'Inicio',
                pathname: '/',
                isActive: pathname === '/',
                icon: HouseIcon,
            },
            {
                title: 'Mis suscripciones',
                pathname: '/subscriptions',
                isActive: pathname === '/subscriptions',
                icon: CarIcon,
            },
            {
                title: 'Protocolos',
                pathname: '/protocols',
                isActive: pathname === '/protocols',
                icon: Clipboard,
            },
        ];

        return buttons.map(button => {
            const { title, pathname, isActive, icon } = button;

            return (
                <Menu.Body.Button key={title} icon={icon} isActive={isActive} onClick={() => { setOpen(!isOpen); navigate(pathname); }}>
                    { title }
                </Menu.Body.Button>
            );
        });
    };

    const renderProfileButton = () => {
        if (_.isEmpty(user)) return null;

        const target = '/profile';
        const isActive = pathname === target;

        return (
            <Menu.Body.Button
                icon={UserIcon}
                isActive={isActive}
                onClick={() => { setOpen(!isOpen); navigate(target); }}
            >
                { user.firstname } { user.lastname }
            </Menu.Body.Button>
        );
    };

    return (
        <Container fluid style={{ background: '#F5F5F5' }} className="px-0">
            <div className="gx-0 layout-container">
                <div className="menu-column">
                    <Menu isOpen={isOpen} setOpen={setOpen}>
                        <Menu.Header>
                            <Menu.Header.Brand src={MainLogo} />
                            <Menu.Header.Button />
                        </Menu.Header>
                        <Menu.Body>
                            <Menu.Body.Group scroll>
                                { renderButtons() }
                            </Menu.Body.Group>
                            <Menu.Body.Group>
                                { renderProfileButton() }
                                <br/>
                            </Menu.Body.Group>
                        </Menu.Body>
                    </Menu>
                </div>
                <div className="p-md-3 content-column">
                    <div className="content-container">
                        { view }
                    </div>
                </div>
            </div>
        </Container>
    );
};

Layout.propTypes = {
    view: PropTypes.node,
};

export default Layout;
