import PropTypes from 'prop-types';

import SquareButton from './square_button';
import SquareButtonGrid from './square_button_grid';
import Paragraph from './paragraph';

import CarTowIcon from '../assets/icons/car-tow';
import CarCollisionIcon from '../assets/icons/car-collision';
import CarBurglarIcon from '../assets/icons/car-burglar';
import WhatsappLogo from '../assets/icons/whatsapp';

const QuickAccessButtons = (props) => {
    const {
        thefts,
        collisions,
        assistance,
        whatsapp,
    } = props;

    return (
        <SquareButtonGrid>
            {
                thefts && (
                    <div>
                        <SquareButton variant="secondaryOutlined" href="tel:6003680300">
                            <CarBurglarIcon />
                        </SquareButton>
                        <Paragraph textAlign="center" size="xxs">
                            Robos
                        </Paragraph>
                    </div>
                )
            }
            {
                collisions && (
                    <div>
                        <SquareButton variant="secondaryOutlined" href="tel:6004111000">
                            <CarCollisionIcon />
                        </SquareButton>
                        <Paragraph textAlign="center" size="xxs">
                            Colisión
                        </Paragraph>
                    </div>
                )
            }
            {
                assistance && (
                    <div>
                        <SquareButton variant="secondaryOutlined" href="tel:6004111000">
                            <CarTowIcon />
                        </SquareButton>
                        <Paragraph textAlign="center" size="xxs">
                            Asistencia
                        </Paragraph>
                    </div>
                )
            }
            {
                whatsapp && (
                    <div>
                        <SquareButton variant="secondaryOutlined" href="https://api.whatsapp.com/send/?phone=56954565621&text=Hola%20buenas!%20Quiero%20hacerles%20una%20consulta">
                            <WhatsappLogo />
                        </SquareButton>
                        <Paragraph textAlign="center" size="xxs">
                            ¿Dudas?
                        </Paragraph>
                    </div>
                )
            }
        </SquareButtonGrid>
    );
};

QuickAccessButtons.propTypes = {
    thefts: PropTypes.bool,
    collisions: PropTypes.bool,
    assistance: PropTypes.bool,
    whatsapp: PropTypes.bool,
};

QuickAccessButtons.defaultProps = {
    thefts: false,
    collisions: false,
    assistance: false,
    whatsapp: false,
};

export default QuickAccessButtons;
