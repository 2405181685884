import _ from 'lodash';

export const setItems = (query) => {
    if (_.isEmpty(query)) return;

    Object.entries(query).forEach(([key, value]) => {
        sessionStorage.setItem(key, value);
    });
};

export const getItems = () => {
    return sessionStorage;
};
