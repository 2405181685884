import _ from 'lodash';
import PropTypes from 'prop-types';

import useVehicleImage from '../../hooks/use_vehicle_image';

import PlaceholderVehicle from '../../assets/images/placeholder-vehicle.png';

import './style.css';

const VehicleCard = (props) => {
    const { vehicle } = props;

    let [ primaryImage ] = useVehicleImage(vehicle.brand, vehicle.model, vehicle.version, vehicle.year, 'IMAGE_PRIMARY');

    if (_.isEmpty(vehicle)) return null;

    if (_.isEmpty(primaryImage)) {
        primaryImage = {
            url: PlaceholderVehicle,
        };
    }

    const { brand, model, version } = vehicle;

    return (
        <div className="vcard-container">
            <div>
                <img
                    className="vcard-image"
                    src={primaryImage.url}
                    alt={`${brand} ${model} ${version}`}
                />
            </div>
            <div>
                <div className="vcard-text-container">
                    <p className="vcard-pretitle">
                        { brand }
                    </p>
                    <p className="vcard-title">
                        { model }
                    </p>
                    <p className="vcard-subtitle">
                        { version }
                    </p>
                </div>
            </div>
        </div>
    );
};

VehicleCard.propTypes = {
    vehicle: PropTypes.any,
};

export default VehicleCard;
