/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Dropdown = (props) => {
    const { title, children } = props;

    const [open, setOpen ] = useState(true);

    const chevronClass = open ? 'cc-dropdown-button' : 'cc-dropdown-button bottom';

    return (
        <div className="cc-dropdown">
            <div
                className="cc-dropdown-header"
                onClick={() => setOpen(!open)}
            >
                <div className="cc-dropdown-title">
                    { title }
                </div>
                <div className={chevronClass} />
            </div>

            { open && children }
        </div>
    );
};

Dropdown.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    children: PropTypes.node.isRequired,
};

export default Dropdown;
