export default () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1323_5514)">
            <path d="M13.6569 2.34314C12.1459 0.832153 10.1368 0 8 0C5.86316 0 3.85413 0.832153 2.34314 2.34314C0.832153 3.85413 0 5.86316 0 8C0 10.1368 0.832153 12.1459 2.34314 13.6569C3.85413 15.1678 5.86316 16 8 16C10.1368 16 12.1459 15.1678 13.6569 13.6569C15.1678 12.1459 16 10.1368 16 8C16 5.86316 15.1678 3.85413 13.6569 2.34314ZM3.47205 13.4155C3.73621 11.1426 5.69043 9.3877 8 9.3877C9.21753 9.3877 10.3624 9.86206 11.2236 10.7231C11.9512 11.4508 12.41 12.4009 12.5281 13.4154C11.3013 14.4429 9.72168 15.0625 8 15.0625C6.27832 15.0625 4.69885 14.443 3.47205 13.4155ZM8 8.42212C6.66028 8.42212 5.57019 7.33203 5.57019 5.99231C5.57019 4.65247 6.66028 3.5625 8 3.5625C9.33972 3.5625 10.4298 4.65247 10.4298 5.99231C10.4298 7.33203 9.33972 8.42212 8 8.42212ZM13.335 12.6229C13.0961 11.6598 12.5979 10.7717 11.8865 10.0603C11.3103 9.48413 10.6287 9.05188 9.88525 8.78101C10.7789 8.17493 11.3673 7.151 11.3673 5.99231C11.3673 4.13562 9.85669 2.625 8 2.625C6.14331 2.625 4.63269 4.13562 4.63269 5.99231C4.63269 7.15161 5.22156 8.1759 6.11597 8.78186C5.43201 9.03113 4.79907 9.4165 4.25452 9.92383C3.46594 10.6582 2.91833 11.5946 2.66443 12.6222C1.58911 11.3826 0.9375 9.76599 0.9375 8C0.9375 4.10571 4.10571 0.9375 8 0.9375C11.8943 0.9375 15.0625 4.10571 15.0625 8C15.0625 9.76636 14.4106 11.3833 13.335 12.6229Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_1323_5514">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
