import React from 'react';
import PropTypes from 'prop-types';

import './style.css';
import _ from 'lodash';

const Card = (props) => {
    const {
        primaryColor,
        secondaryColor,
        textColor,
        title,
        badge,
        ribbon,
        subtitle,
        src,
        imageFilter,
        defaultSrc,
        children,
    } = props;

    const renderTitle = (title) => {
        if (_.isString(title)) {
            return (
                <div className="sc-header-container">
                    <p className="sc-header-title" style={{ color: textColor }}>
                        { title }
                    </p>
                    { badge }
                </div>
            );
        }

        return title;
    };

    const renderSubtitle = (subtitle) => {
        if (_.isString(subtitle)) {
            return (
                <p className="sc-header-subtitle" style={{ color: textColor }}>
                    { subtitle }
                </p>
            );
        }

        return subtitle;
    };

    const handleOnError = (event) => {
        const { target } = event;
        target.onerror = null;

        target.src = defaultSrc;
        target.style.top = '40%';
        target.style.height = '30%';
    };

    return (
        <div className="sc-container">
            <div className="sc-header">
                <div className="sc-header-content">
                    <img
                        src={src}
                        alt={`${title} ${subtitle}`}
                        onError={handleOnError}
                        style={{ filter: imageFilter }}
                    />
                </div>
                <div className="sc-header-background">
                    <div className="sc-header-background-top" style={{ backgroundColor: secondaryColor }}>
                        { renderTitle(title) }
                        { renderSubtitle(subtitle) }
                    </div>
                    <div className="sc-header-background-bottom" style={{ backgroundColor: primaryColor }} />
                </div>
            </div>
            <div className="sc-body" style={{ backgroundColor: primaryColor }}>
                { children }
            </div>
            {
                ribbon && (
                    <div className="sc-ribbon">
                        { ribbon }
                    </div>
                )
            }
        </div>
    );
};

Card.propTypes = {
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired,
    ]),
    subtitle: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node.isRequired,
    ]),
    badge: PropTypes.node,
    ribbon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    src: PropTypes.string.isRequired,
    defaultSrc: PropTypes.string.isRequired,
    children: PropTypes.node,
    imageFilter: PropTypes.oneOf([
        false, 'grayscale(1)',
    ]),
};

export default Card;
