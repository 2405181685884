export default (props) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12.6854 2.0963H11.2379V1.44874C11.2379 1.23923 11.0094 1.144 10.7999 1.144H9.67618C9.40955 0.382157 8.74292 0.00123321 7.98107 0.00123321C7.22761 -0.0270927 6.54228 0.434908 6.28596 1.144H5.18129C4.97178 1.144 4.76227 1.23923 4.76227 1.44874V2.0963H3.31473C2.45693 2.10545 1.75516 2.78213 1.71484 3.63902V14.5525C1.71484 15.3905 2.47669 16 3.31473 16H12.6854C13.5235 16 14.2853 15.3905 14.2853 14.5525V3.63906C14.245 2.78213 13.5432 2.10545 12.6854 2.0963ZM5.52408 1.90585H6.57163C6.75449 1.88354 6.90137 1.74443 6.93352 1.56302C7.04632 1.07182 7.47726 0.719225 7.98107 0.705955C8.48022 0.721084 8.90468 1.07478 9.00956 1.56302C9.04372 1.7507 9.20029 1.8916 9.39048 1.90585H10.4761V3.42955H5.52408V1.90585ZM13.5235 14.5525C13.5235 14.9715 13.1045 15.2381 12.6854 15.2381H3.31473C2.89571 15.2381 2.47669 14.9715 2.47669 14.5525V3.63906C2.51555 3.2029 2.8769 2.86616 3.31473 2.85818H4.76223V3.82954C4.78236 4.04295 4.96719 4.20259 5.18125 4.19143H10.7999C11.0179 4.20335 11.2085 4.0459 11.2379 3.82954V2.85814H12.6854C13.1232 2.86616 13.4846 3.20287 13.5234 3.63902V14.5525H13.5235Z" fill="currentColor" />
        <path d="M6.53352 8.51487C6.39065 8.36428 6.15347 8.35578 6.00021 8.49584L4.78125 9.65765L4.26701 9.12434C4.12414 8.97374 3.88696 8.96528 3.7337 9.10531C3.58616 9.25988 3.58616 9.50307 3.7337 9.65765L4.51458 10.4576C4.58216 10.5333 4.67979 10.5751 4.78121 10.5719C4.88168 10.5705 4.97752 10.5294 5.04785 10.4576L6.53345 9.04818C6.68073 8.91308 6.69057 8.68414 6.55543 8.53689C6.5485 8.5292 6.54117 8.52187 6.53352 8.51487Z" fill="currentColor" />
        <path d="M11.9998 9.33386H7.6192C7.40882 9.33386 7.23828 9.5044 7.23828 9.71479C7.23828 9.92517 7.40882 10.0957 7.6192 10.0957H11.9998C12.2102 10.0957 12.3807 9.92517 12.3807 9.71479C12.3807 9.5044 12.2102 9.33386 11.9998 9.33386Z" fill="currentColor" />
        <path d="M6.53352 5.46751C6.39065 5.31691 6.15347 5.30842 6.00021 5.44848L4.78125 6.61028L4.26701 6.07698C4.12414 5.92638 3.88696 5.91788 3.7337 6.05795C3.58616 6.21252 3.58616 6.45571 3.7337 6.61028L4.51458 7.41023C4.58216 7.48591 4.67979 7.52776 4.78121 7.52451C4.88168 7.52309 4.97752 7.48201 5.04785 7.41023L6.53345 6.00082C6.68073 5.86572 6.69057 5.63677 6.55543 5.48953C6.5485 5.48184 6.54117 5.47451 6.53352 5.46751Z" fill="currentColor" />
        <path d="M11.9998 6.2865H7.6192C7.40882 6.2865 7.23828 6.45704 7.23828 6.66742C7.23828 6.87781 7.40882 7.04835 7.6192 7.04835H11.9998C12.2102 7.04835 12.3807 6.87781 12.3807 6.66742C12.3807 6.45704 12.2102 6.2865 11.9998 6.2865Z" fill="currentColor" />
        <path d="M6.53352 11.5622C6.39065 11.4116 6.15347 11.4032 6.00021 11.5432L4.78125 12.705L4.26701 12.1717C4.12414 12.0211 3.88696 12.0126 3.7337 12.1527C3.58616 12.3072 3.58616 12.5504 3.7337 12.705L4.51458 13.5049C4.58216 13.5806 4.67979 13.6225 4.78121 13.6192C4.88168 13.6178 4.97752 13.5767 5.04785 13.5049L6.53345 12.0955C6.68073 11.9604 6.69057 11.7315 6.55543 11.5842C6.5485 11.5766 6.54117 11.5693 6.53352 11.5622Z" fill="currentColor" />
        <path d="M11.9998 12.3812H7.6192C7.40882 12.3812 7.23828 12.5518 7.23828 12.7621C7.23828 12.9725 7.40882 13.1431 7.6192 13.1431H11.9998C12.2102 13.1431 12.3807 12.9725 12.3807 12.7621C12.3807 12.5518 12.2102 12.3812 11.9998 12.3812Z" fill="currentColor" />
    </svg>
);
