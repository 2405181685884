import { useEffect, useState } from 'react';
import Loader from '../../components/loader';

const MESSAGES = [
    'Lavado de tu auto gratis una vez al mes en estaciones Lavamax',
    '3.000 Puntos Full Copec todos los meses',
    'Un auto eléctrico de prueba gratis por una semana',
    'Servicio y mantenimiento preventivo',
    'Revisión Técnica',
    'Permiso de circulación',
    'Seguro Total',
    'Auto de reemplazo',
    'Asistencia en Ruta',
];

const DynamicLoader = (props) => {
    const nextProps = props;
    delete props.children;

    const [currentMessage, setCurrentMessage] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (currentMessage + 1 === MESSAGES.length) {
                setCurrentMessage(0);
            } else {
                setCurrentMessage(currentMessage + 1);
            }
        }, 4000);
    }, [currentMessage]);

    const message = MESSAGES[currentMessage];

    return (
        <Loader {...nextProps}>
            <br/>
            <p>
                Cargando...
            </p>
            <br/>
            <p style={{ fontSize: '1rem' }}>
                Sabías que tu suscripción incluye...
            </p>
            <p style={{ fontSize: '1rem' }}>
                <strong>{ message }</strong>
            </p>
        </Loader>
    );
};

DynamicLoader.propTypes = {
    ...Loader.propTypes,
};

export default DynamicLoader;
