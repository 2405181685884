import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Paragraph from '../../components/paragraph';
import Select from '../../components/select';
import Button from '../../components/button';

import apiService from '../../services/api_service';

const FIELDS = {
    LICENSE_CLASS: {
        id: 'license_class',
        regex: /^.+$/,
        error: 'Debes seleccionar una opción',
    },
    LICENSE_EMISSION: {
        id: 'license_emission',
        regex: /^.+$/,
        error: 'Debes seleccionar una opción',
    },
    LICENSE_EXPIRATION: {
        id: 'license_expiration',
        regex: /^.+$/,
        error: 'Debes seleccionar una opción',
    },
    LICENSE_USAGE: {
        id: 'vehicle_usage',
        regex: /^.+$/,
        error: 'Debes seleccionar una opción',
    },
};

const OnboardingDriverData = (props) => {
    const { userId, subscriptionId, formState, onNextStep, onFormChange } = props;
    const [formErrors, setFormErrors] = useState({});
    const [nextDisabled, setNextDisabled] = useState(true);
    const [submitError, setSubmitError] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateField = (id, value) => {
        const field = Object.values(FIELDS).find(field => field.id === id);

        if (field === undefined) return false;

        return field.regex.test(value ?? '');
    };

    const getFieldError = (id) => {
        const field = Object.values(FIELDS).find(field => field.id === id);

        if (field === undefined) return false;

        return field.error;
    };

    useEffect(() => {
        const isValid = Object.values(FIELDS).every(field => validateField(field.id, formState[field.id]));

        setNextDisabled(!isValid);
    }, [formState]);

    const handleOnChange = (event) => {
        const { id, value } = event.target;

        const newFormState = {
            ...formState,
            [id]: value,
        };

        onFormChange(newFormState);
        setFormErrors({ ...formErrors, [id]: undefined });
    };

    const handleOnBlur = (event) => {
        const { id, value } = event.target;

        const validField = validateField(id, value);

        let error;
        if (!validField) {
            error = getFieldError(id);
        }

        setFormErrors({ ...formErrors, [id]: error });
    };

    const handleOnClick = async () => {
        try {
            setIsSubmitting(true);
            setSubmitError(undefined);
            await apiService.post('onboardingDriver', { ...formState, userId });

            const data = await apiService.post('onboardingValidate', { userId, subId: subscriptionId });

            onNextStep(data.data);
        } catch {
            setSubmitError('Tuvimos un problema para enviar tus datos');
        }
        setIsSubmitting(false);
    };

    return (
        <>
            <Paragraph>
                Para finalizar completa los datos relacionados con tu liencia de conducir
            </Paragraph>
            <Select
                className="mt-4"
                id={FIELDS.LICENSE_CLASS.id}
                value={formState[FIELDS.LICENSE_CLASS.id] ?? ''}
                error={formErrors[FIELDS.LICENSE_CLASS.id]}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            >
                <option value="" disabled>Tipo de Licencia</option>
                <option value="CLASS_A">Clase A</option>
                <option value="CLASS_B">Clase B</option>
                <option value="CLASS_C">Clase C</option>
                <option value="OTHER">Otra</option>
            </Select>
            <Select
                className="mt-4"
                id={FIELDS.LICENSE_EMISSION.id}
                value={formState[FIELDS.LICENSE_EMISSION.id] ?? ''}
                error={formErrors[FIELDS.LICENSE_EMISSION.id]}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            >
                <option value="" disabled>Emisión de Primera Licencia</option>
                <option value="RANGE_0_2">0 - 2 años</option>
                <option value="RANGE_2">Mayor a 2 años</option>
            </Select>
            <Select
                className="mt-4"
                id={FIELDS.LICENSE_EXPIRATION.id}
                value={formState[FIELDS.LICENSE_EXPIRATION.id] ?? ''}
                error={formErrors[FIELDS.LICENSE_EXPIRATION.id]}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            >
                <option value="" disabled>Vencimiento de Licencia Actual</option>
                <option value="RANGE_0_2">Vence entre 0 a 2 meses</option>
                <option value="RANGE_2">Vence en más de 2 meses</option>
            </Select>
            <Select
                className="mt-4"
                id={FIELDS.LICENSE_USAGE.id}
                value={formState[FIELDS.LICENSE_USAGE.id] ?? ''}
                error={formErrors[FIELDS.LICENSE_USAGE.id]}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            >
                <option value="" disabled>Tipo de uso automóvil</option>
                <option value="PERSONAL">Particular</option>
                <option value="COMMERCIAL">Comercial</option>
                <option value="APPS">Apps (Uber, Cabify, Delivery)</option>
            </Select>
            <Button
                className="mt-5"
                block
                loading={isSubmitting}
                onClick={handleOnClick}
                disabled={nextDisabled}
            >
                Siguiente
            </Button>
            {
                submitError && (
                    <Paragraph variant="error" textAlign="center">
                        No pudimos enviar la información
                    </Paragraph>
                )
            }
        </>
    );
};

OnboardingDriverData.propTypes = {
    userId: PropTypes.string,
    subscriptionId: PropTypes.string,
    formState: PropTypes.any,
    onFormChange: PropTypes.func,
    onNextStep: PropTypes.func,
};

OnboardingDriverData.defaultProps = {
    formState: {},
};

export default OnboardingDriverData;
