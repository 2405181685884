import { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../../components/title';
import Paragraph from '../../components/paragraph';
import SquareButton from '../../components/square_button';
import SquareButtonGrid from '../../components/square_button_grid';
import Button from '../../components/button';
import TabController from '../../components/tab_controller';

import CarWheelsIcon from '../../assets/icons/car-wheels';
import WrenchIcon from '../../assets/icons/wrench';
import CarShield from '../../assets/icons/car-shield';
import CarLift from '../../assets/icons/car-lift';
import JuryTicket from '../../assets/icons/jury-ticket';
import ContractEnded from '../../assets/icons/contract-ended';
import ExclamationMark from '../../assets/icons/exclamation-mark';
import CarBurglarIcon from '../../assets/icons/car-burglar';
import DocumentIcon from '../../assets/icons/document';

import Modal from 'react-bootstrap/Modal';

const PROCESSES = {
    wheelReplacement: {
        icon: CarWheelsIcon,
        title: 'Cambio de neumáticos',
        paragraphs: [
            <Paragraph key="first-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Debes tener en cuenta que el cambio de neumáticos que está incluído en la cuota mensual se refiere a cambio requerido por desgaste natural.</Paragraph.Primary> En caso de pinchazo puedes contactar con el seguro, quienes tienen servicio de reparación in situ.
            </Paragraph>,
            <Paragraph key="second-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Notificación:</Paragraph.Primary> Si se acerca el momento de hacer cambio de neumáticos por el kilometraje acumulado del auto, te contactaremos para coordinar el retiro de tu vehículo.
            </Paragraph>,
            <Paragraph key="third-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Retiro vehículo:</Paragraph.Primary> El retiro del vehículo lo realizará uno de nuestros choferes en caso de ser posible, de lo contrario, comunícate con SURA al <Button href="tel:600 411 1000" className="inline" variant="link" fontWeight="bold">600 411 1000</Button> para que una grúa vaya a retirarlo.
            </Paragraph>,
            <Paragraph key="fourth-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Taller:</Paragraph.Primary> Nosotros nos encargaremos de gestionar el cambio de los neumáticos en el taller.
            </Paragraph>,
            <Paragraph key="fifth-paragraph" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Devolución:</Paragraph.Primary> Una vez esté listo tu auto, te contactaremos para coordinar la devolución.
            </Paragraph>,
        ],
    },
    maintenance: {
        icon: WrenchIcon,
        title: 'Mantenciones',
        paragraphs: [
            <Paragraph key="first-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Aviso mantención:</Paragraph.Primary> Te contactaremos cuando se aproxime el momento de realizarle mantenimiento al vehículo (10.000 km, 20.000 km, 30.000 km, etc.).
            </Paragraph>,
            <Paragraph key="second-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Agendamiento hora en taller:</Paragraph.Primary> Nos encargamos de agendar la hora de la mantención de tu vehículo, no debes preocuparte de nada.
            </Paragraph>,
            <Paragraph key="third-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Retiro vehículo:</Paragraph.Primary> Uno de nuestros choferes se encargará de llevar tu auto a la cita de mantención, por lo que nos contactaremos contigo para coordinar dónde y cuando pasar por tu vehículo.
            </Paragraph>,
            <Paragraph key="fourth-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Cupón Uber:</Paragraph.Primary> Para que no te quedes sin moverte, te será entregado un cupón de Uber que podrás utilizar mientras tu auto esté en el taller.
            </Paragraph>,
            <Paragraph key="fifth-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Vehículo de reemplazo:</Paragraph.Primary> En caso de que tu auto deba estar más de 48 horas en el taller, te entregaremos un vehículo de reemplazo en la puerta de tu casa.
            </Paragraph>,
            <Paragraph key="sixth-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Devolución vehículo:</Paragraph.Primary> Cuando tu auto ya esté listo, uno de nuestros choferes lo llevará directamente donde tú lo desees.
            </Paragraph>,
            <Paragraph key="seventh-paragraph" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Aprobación:</Paragraph.Primary> Si estás conforme con el resultado de la mantención, podrás seguir disfrutando de tu auto connect car. En caso contrario, nos ocuparemos de que quede listo para que puedas volver a utilizarlo.
            </Paragraph>,
        ],
    },
    replacement: {
        icon: CarShield,
        title: 'Vehículo de reemplazo',
        tabs: [
            {
                title: 'Siniestro',
                paragraphs: [
                    <Paragraph key="1-paragraph" className="mb-3" textAlign="justify" size="xs">
                        <Paragraph.Primary fontWeight="bold">Vehículo de reemplazo es entregado por SURA</Paragraph.Primary>
                    </Paragraph>,
                    <Paragraph key="2-paragraph" className="mb-3" textAlign="justify" size="xs">
                        <Paragraph.Primary fontWeight="bold">Notificación seguro:</Paragraph.Primary> Comunícate con SURA al <Button href="tel:600 411 1000" className="inline" variant="link" fontWeight="bold">600 411 1000</Button>. Para cualquier siniestro indemnizable, tendrás derecho a un vehículo de reemplazo en caso de que: 1. El auto deba estar más de 24 horas en reparación. 2. El auto deba permanecer inmovilizado más de 24 horas antes de ser ingresado al taller.
                    </Paragraph>,
                    <Paragraph key="3-paragraph" className="mb-3" textAlign="justify" size="xs">
                        <Paragraph.Primary fontWeight="bold">Coordinación de entrega:</Paragraph.Primary> Una vez sea ingresado el vehículo siniestrado al taller, se procederá a coordinar la entrega.
                    </Paragraph>,
                    <Paragraph key="4-paragraph" textAlign="justify" size="xs">
                        <Paragraph.Primary fontWeight="bold">Perdida total/Robo:</Paragraph.Primary> En el caso de pérdida total a consecuencia de robo, hurto o apropiación indebida; la entrega del vehículo de reemplazo se hará efectiva 24 horas hábiles después de declarado formalmente como tal por la compañía de seguros.
                    </Paragraph>,
                ],
            },
            {
                title: 'Problema o defecto',
                paragraphs: [
                    <Paragraph key="5-paragraph" className="mb-3" textAlign="justify" size="xs">
                        <Paragraph.Primary fontWeight="bold">En caso de problemas o desperfectos en el vehículo que no sean cubiertos por el seguro y que requieran tiempos de reparación mayores a 48 hrs hábiles: vehículo de reemplazo es entregado por connect car.</Paragraph.Primary>
                    </Paragraph>,
                    <Paragraph key="6-paragraph" className="mb-3" textAlign="justify" size="xs">
                        <Paragraph.Primary fontWeight="bold">Notificación connect car:</Paragraph.Primary> Si tu auto sufre algún desperfecto que no te permite usarlo de manera adecuada, infórmanos al respecto.
                    </Paragraph>,
                    <Paragraph key="7-paragraph" className="mb-3" textAlign="justify" size="xs">
                        <Paragraph.Primary fontWeight="bold">Retiro/Revisión:</Paragraph.Primary> Enviaremos a uno de nuestros choferes a retirar tu auto. En caso de tener que estar más de 48 horas en el taller, te entregaremos un vehículo de reemplazo lo más similar posible al que ya tienes, sin embargo, depende de la disponibilidad del momento.
                    </Paragraph>,
                    <Paragraph key="8-paragraph" className="mb-3" textAlign="justify" size="xs">
                        <Paragraph.Primary fontWeight="bold">Coordinación de entrega:</Paragraph.Primary> Coordinaremos la entrega de tu auto de reemplazo y enviaremos a un chofer para que te lo entregue en la puerta de tu casa.
                    </Paragraph>,
                    <Paragraph key="9-paragraph" textAlign="justify" size="xs">
                        <Paragraph.Primary fontWeight="bold">Notificación vehículo:</Paragraph.Primary> Una vez tu vehículo esté listo para ser entregado, te lo notificaremos y coordinaremos contigo para devolvértelo.
                    </Paragraph>,
                ],
            },
        ],
    },
    techCheck: {
        icon: CarLift,
        title: 'Revisión técnica',
        paragraphs: [
            <Paragraph key="first-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Notificación:</Paragraph.Primary> Cuando se aproxime la fecha de vencimiento de la revisión técnica de tu auto, te avisaremos para poder coordinar la fecha de retiro.
            </Paragraph>,
            <Paragraph key="third-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Retiro vehículo:</Paragraph.Primary> Enviaremos a uno de nuestros choferes a retirar tu auto y llevarlo a la revisión técnica.
            </Paragraph>,
            <Paragraph key="fourth-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Aprobación revisión:</Paragraph.Primary> Cuando la revisión esté lista y aprobada, el auto será llevado a la puerta de tu casa.
            </Paragraph>,
            <Paragraph key="fifth-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Rechazo revisión:</Paragraph.Primary> En caso de ser rechazada la revisión, nosotros nos encargamos de solucionar el problema y volver a realizarla.
            </Paragraph>,
            <Paragraph key="seventh-paragraph" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Devolución vehículo:</Paragraph.Primary> Apenas esté listo tu auto, coordinaremos contigo para hacerte entrega de éste.
            </Paragraph>,
        ],
    },
    juryTicket: {
        icon: JuryTicket,
        title: 'Multas',
        paragraphs: [
            <Paragraph key="first-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Notificación:</Paragraph.Primary> Lo primero que debes hacer es notificarnos acerca de la multa que fue cursada. En caso de tratarse de una multa hacia el conductor, ellos son los encargados de la gestión de la multa, pagando de manera presencial en el Municipio donde fue cursada o en el Juzgado de Policía Local. Si se trata de una multa al vehículo, nosotros nos encargamos de la gestión.
            </Paragraph>,
            <Paragraph key="seventh-paragraph" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Pago multa:</Paragraph.Primary> La gestión del pago de la multa hacia el vehículo lo haremos nosotros y será adicionado a la próxima mensualidad que debes cancelar. Cuando ya hayamos pagado la multa, te informaremos a la brevedad.
            </Paragraph>,
        ],
    },
    contractEnded: {
        icon: ContractEnded,
        title: 'Baja anticipada suscripción',
        paragraphs: [
            <Paragraph key="1-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Notificación:</Paragraph.Primary> Lo primero que debes hacer si quieres dar de baja tu suscripción es avisarnos por alguno de nuestros canales.
            </Paragraph>,
            <Paragraph key="2-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Términos y condiciones:</Paragraph.Primary> Podrás acceder a los términos y condiciones de la baja anticipada del servicio directamente en el contrato que firmaste con nosotros, sin embargo, ante cualquier interrogante, puedes contactarnos y te ayudaremos a aclarar todas tus dudas.
            </Paragraph>,
            <Paragraph key="3-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Formulario devolución:</Paragraph.Primary> Te será enviado un formulario para que puedas dejar constancia de tu término de servicio.
            </Paragraph>,
            <Paragraph key="4-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Pago penalización:</Paragraph.Primary> Si aceptas las condiciones de la baja anticipada, te enviaremos el link correspondiente para que puedas hacer el pago de la penalización.
            </Paragraph>,
            <Paragraph key="5-paragraph" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Retiro vehículo:</Paragraph.Primary> Enviaremos a uno de nuestros choferes a retirar tu vehículo donde estimes conveniente. En ese momento, se hará una inspección para validar que esté todo en orden.
            </Paragraph>,
        ],
    },
    unexpectedSituations: {
        icon: ExclamationMark,
        title: 'Imprevistos',
        paragraphs: [
            <Paragraph key="1-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Notificación siniestro:</Paragraph.Primary> Ante un problema no programado (como lo es un accidente de tránsito o un pinchazo), debes dar aviso a la aseguradora SURA a través de uno de los siguientes canales:
            </Paragraph>,
            <div key="1-list" className="text-start">
                <ul>
                    <li>
                        <Paragraph size="xs">
                            Call Center: <Button href="tel:600 411 1000" className="inline" variant="link" fontWeight="bold">600 411 1000</Button>.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph size="xs">
                            Página web: <Button href="https://seguros.sura.cl" className="inline" variant="link" fontWeight="bold" target="_blank" rel="noopener noreferrer">https://seguros.sura.cl</Button>
                        </Paragraph>
                    </li>
                </ul>
            </div>,
            <Paragraph key="2-paragraph" className="mb-3" textAlign="justify" size="xs">
                Ellos te solicitarán los siguientes datos:
            </Paragraph>,
            <div key="2-list" className="text-start">
                <ul>
                    <li>
                        <Paragraph size="xs">
                            N° Póliza: 7665709
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph size="xs">
                            Contratante: Soluciones de Movilidad
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph size="xs">
                            Rut contratante: 77.088.206-0
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph size="xs">
                            Asegurado: Rentas y Servicios S.A.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph size="xs">
                            Rut asegurado: 76.095.267-2
                        </Paragraph>
                    </li>
                </ul>
            </div>,
            <Paragraph key="3-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Asistencia en ruta:</Paragraph.Primary>
            </Paragraph>,
            <div key="3-list" className="text-start">
                <ul>
                    <li className="mb-3">
                        <Paragraph size="xs">
                            <b>Remolque o transporte:</b> En caso de que el vehículo no pudiera circular por avería o accidente, el servicio de asistencia se hará cargo del remolque o transporte hasta el taller que indique el titular. Esta prestación contempla el traslado del titular y sus acompañantes hasta el taller o hasta su domicilio.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph size="xs">
                            <b>Reparación in situ:</b> Incluye los servicios de cambio de neumático, puesta en marcha por batería descargada, reposición de combustible (hasta 5 litros con cargo al asegurado), apertura de puertas y mecánica ligera. En caso de que el vehículo no pudiera ser reparado en el tiempo indicado, entrará en vigor la prestación de remolque.
                        </Paragraph>
                    </li>
                </ul>
            </div>,
            <Paragraph key="4-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Taller:</Paragraph.Primary> En connect car nos preocupamos de toda la gestión relacionada a la estadía de tu vehículo en taller. Es decir, coordinaremos con el seguro la visita del liquidador y te informaremos la fecha aproximada de retiro, el que será efectuado por uno de nuestros choferes.
            </Paragraph>,
            <Paragraph key="5-paragraph" className="mb-3" textAlign="justify" size="xs">
                * Para información sobre el servicio de vehículo de reemplazo, ver apartado correspondiente.
            </Paragraph>,
            <Paragraph key="6-paragraph" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Devolución vehículo:</Paragraph.Primary> Una vez esté lista la reparación, coordinaremos contigo para poder entregarte tu auto dónde y cuándo estimes conveniente.
            </Paragraph>,
        ],
    },
    theft: {
        icon: CarBurglarIcon,
        title: 'Robos y portonazos',
        paragraphs: [
            <Paragraph key="0-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Llamado instantáneo:</Paragraph.Primary> Dar aviso de manera inmediata al número <Button href="tel:600 368 0300" className="inline" variant="link" fontWeight="bold">600 368 0300</Button> y entregar el número de patente. Desde esta central se harán cargo de gestionar el corte de la corriente del vehículo.
            </Paragraph>,
            <Paragraph key="1-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Denuncia policial:</Paragraph.Primary> En caso de sufrir el robo del vehículo o de alguna de sus piezas, lo primero que debes hacer es realizar la denuncia en Comisaría de Carabineros, Policía de Investigaciones u oficina del Ministerio Público más cercana.
            </Paragraph>,
            <Paragraph key="2-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Envío correo:</Paragraph.Primary> Efectuado el trámite anterior, deberá enviar un correo electrónico al área de siniestro señalando en su asunto: Siniestro Patente XXXXXX. Email destino: <Button href="mailto:siniestro@avisbudget.cl" className="inline" variant="link" fontWeight="bold">siniestro@avisbudget.cl</Button>
            </Paragraph>,
            <Paragraph key="3-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Ingreso taller:</Paragraph.Primary> El área de siniestro, una vez conforme con la documentación proporcionada, crea ID y aprueba el ingreso al taller con nuestros asesores; <Button href="mailto:recepciondeservicio@avisbudget.cl" className="inline" variant="link" fontWeight="bold">recepciondeservicio@avisbudget.cl</Button>.
                Contacto área de siniestro: <Button href="tel:+56 2 2795 3924" className="inline" variant="link" fontWeight="bold">+56 2 2795 3924</Button> o <Button href="tel:+56 9 9827 8926" className="inline" variant="link" fontWeight="bold">+56 9 9827 8926</Button>
            </Paragraph>,
            <Paragraph key="4-paragraph" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Notificación siniestro:</Paragraph.Primary> Cuando ya estén completos los pasos anteriores, avísanos directamente a nuestro teléfono de connect car: <Button href="tel:+56 9 5456 5621" className="inline" variant="link" fontWeight="bold">+56 9 5456 5621</Button> para que estemos al tanto de tu situación.
            </Paragraph>,
        ],
    },
    documents: {
        icon: DocumentIcon,
        title: 'Documentos',
        paragraphs: [
            <Paragraph key="1-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Renovación Permiso de Circulación + SOAP</Paragraph.Primary>
            </Paragraph>,
            <Paragraph key="2-paragraph" className="mb-3" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Notificación:</Paragraph.Primary> Desde connect car te notificaremos cuando hayamos renovado tanto el permiso de circulación como el SOAP.
            </Paragraph>,
            <Paragraph key="3-paragraph" textAlign="justify" size="xs">
                <Paragraph.Primary fontWeight="bold">Envío de documentos:</Paragraph.Primary> Cuando ya hayamos hecho el pago, te enviaremos los papeles físicos por correo para que puedas tenerlos al día en tu auto.
            </Paragraph>,
        ],
    },
};

const Page = () => {
    const [showModal, setShowModal] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);

    const renderModalTabs = (tabs) => {
        return (
            <TabController>
                {
                    tabs.map((tab, index) => {
                        const { title } = tab;
                        return (
                            <TabController.Tab
                                key={`tab-${index}`}
                                value={index}
                                onClick={setSelectedTab}
                                isActive={selectedTab === index}
                            >
                                { title }
                            </TabController.Tab>
                        );
                    })
                }
            </TabController>
        );
    };

    const renderModal = () => {
        const processInfo = PROCESSES[showModal];

        if (!processInfo) return;

        const { icon, title, paragraphs, tabs } = processInfo;

        const Icon = icon;

        let body = paragraphs;
        if (tabs) {
            body = (
                <>
                    { renderModalTabs(tabs) }
                    <br/>
                    { tabs[selectedTab].paragraphs }
                </>
            );
        }

        return (
            <Modal
                show={true}
                size="lg"
                style={{ paddingTop: '5rem' }}
                bsPrefix="rounded-modal modal"
            >
                <div className="text-center py-4 px-3 px-md-5">
                    <Icon style={{ width: '5rem', height: '100%', color: '#00D880' }} />
                    <Title size="sm" variant="dark" className="mt-3 mb-5">
                        { title }
                    </Title>
                    { body }
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }}>
                            <Button className="mt-5 mb-4" onClick={() => setShowModal()} block>
                                Entendido
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        );
    };

    return (
        <>
            { renderModal() }
            <Title variant="secondaryNewTheme">
                Protocolos
            </Title>
            <br />
            <Paragraph variant="primaryNewTheme" fontWeight="bold">
                Ante los distintos eventos que pudieran ocurrir al suscribirte a nuestro servicio, te dejamos un paso a paso de qué hacer en cada una de las siguientes situaciones:
            </Paragraph>
            <br/>
            <Row>
                <Col>
                    <SquareButtonGrid>
                        <div>
                            <SquareButton variant="secondaryOutlined" onClick={() => setShowModal('wheelReplacement')}>
                                <CarWheelsIcon />
                            </SquareButton>
                            <Paragraph textAlign="center" size="xxs">
                                Neumáticos
                            </Paragraph>
                        </div>
                        <div>
                            <SquareButton variant="secondaryOutlined" onClick={() => setShowModal('maintenance')}>
                                <WrenchIcon />
                            </SquareButton>
                            <Paragraph textAlign="center" size="xxs">
                                Mantenciones
                            </Paragraph>
                        </div>
                        <div>
                            <SquareButton variant="secondaryOutlined" onClick={() => setShowModal('replacement')}>
                                <CarShield />
                            </SquareButton>
                            <Paragraph textAlign="center" size="xxs">
                                Reemplazo
                            </Paragraph>
                        </div>
                        <div>
                            <SquareButton variant="secondaryOutlined" onClick={() => setShowModal('techCheck')}>
                                <CarLift />
                            </SquareButton>
                            <Paragraph textAlign="center" size="xxs">
                                Rev. Técnica
                            </Paragraph>
                        </div>
                        <div>
                            <SquareButton variant="secondaryOutlined" onClick={() => setShowModal('juryTicket')}>
                                <JuryTicket />
                            </SquareButton>
                            <Paragraph textAlign="center" size="xxs">
                                Multas
                            </Paragraph>
                        </div>
                        <div>
                            <SquareButton variant="secondaryOutlined" onClick={() => setShowModal('contractEnded')}>
                                <ContractEnded />
                            </SquareButton>
                            <Paragraph textAlign="center" size="xxs">
                                Término Contrato
                            </Paragraph>
                        </div>
                        <div>
                            <SquareButton variant="secondaryOutlined" onClick={() => setShowModal('unexpectedSituations')}>
                                <ExclamationMark />
                            </SquareButton>
                            <Paragraph textAlign="center" size="xxs">
                                Imprevistos
                            </Paragraph>
                        </div>
                        <div>
                            <SquareButton variant="secondaryOutlined" onClick={() => setShowModal('theft')}>
                                <CarBurglarIcon />
                            </SquareButton>
                            <Paragraph textAlign="center" size="xxs">
                                Robos
                            </Paragraph>
                        </div>
                        <div>
                            <SquareButton variant="secondaryOutlined" onClick={() => setShowModal('documents')}>
                                <DocumentIcon />
                            </SquareButton>
                            <Paragraph textAlign="center" size="xxs">
                                Documentos
                            </Paragraph>
                        </div>
                    </SquareButtonGrid>
                </Col>
            </Row>
        </>
    );
};

export default Page;
