import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Paragraph from '../../components/paragraph';
import Input from '../../components/input';
import Button from '../../components/button';

import apiService from '../../services/api_service';

import { getItems } from '../../helpers/analytics_tracker';
import { formatRut, clearRut } from '../../helpers/formatters';

const FIELDS = {
    FIRSTNAME: {
        id: 'firstname',
        regex: /^.{3,}$/,
        error: 'El nombre debe tener un largo de 3 o más caracteres',
    },
    LASTNAME: {
        id: 'lastname',
        regex: /^[^\s]{2,}(\s[^\s]{2,})+$/,
        error: 'Debes ingresar ambos apellidos',
    },
    EMAIL: {
        id: 'email',
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        error: 'El formato del email no es correcto',
    },
    PHONE: {
        id: 'phone',
        regex: /^56[0-9]{9}$/,
        error: 'El teléfono debe comenzar con 56 y tener largo 11',
    },
    NATIONAL_ID: {
        id: 'nationalId',
        regex: /^[1-9]\d*[\dk]$/,
        error: 'Debes ingresar tu rut',
    },
    COUPON: {
        id: 'coupon',
        regex: /^.*$/,
        error: 'El teléfono debe comenzar con 56 y tener largo 11',
    },
    TERMS_AND_CONDITIONS: {
        id: 'terms_and_conditions',
        regex: /^true$/,
        error: 'Debes aceptar los términos y condiciones',
    },
    PRIVACY_POLICY: {
        id: 'privacy_policy',
        regex: /^true$/,
        error: 'Debes aceptar la política de privacidad',
    },
};

const OnboardingIdentityData = (props) => {
    const { vehicle, vehiclePricing, formState, onNextStep, onFormChange } = props;
    const [formErrors, setFormErrors] = useState({});
    const [nextDisabled, setNextDisabled] = useState(true);
    const [submitError, setSubmitError] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateField = (id, value) => {
        const field = Object.values(FIELDS).find(field => field.id === id);

        if (field === undefined) return false;

        return field.regex.test(value ?? '');
    };

    const getFieldError = (id) => {
        const field = Object.values(FIELDS).find(field => field.id === id);

        if (field === undefined) return false;

        return field.error;
    };

    useEffect(() => {
        if (_.isEmpty(formState)) return;

        const newFormState = { ...formState };

        delete newFormState[FIELDS.PRIVACY_POLICY.id];
        delete newFormState[FIELDS.TERMS_AND_CONDITIONS.id];
        delete newFormState[FIELDS.COUPON.id];

        onFormChange(newFormState);
    }, []);

    useEffect(() => {
        const isValid = Object.values(FIELDS).every(field => validateField(field.id, formState[field.id]));

        setNextDisabled(!isValid);
    }, [formState]);

    const handleOnChange = (event) => {
        const { id, value, type, checked } = event.target;

        const newFormState = { ...formState };

        if (type === 'checkbox') {
            newFormState[id] = checked;
            handleOnBlur(event);
        } else {
            newFormState[id] = value;

            if (id === FIELDS.NATIONAL_ID.id) {
                newFormState[id] = clearRut(value);
            }

            setFormErrors({ ...formErrors, [id]: undefined });
        }

        onFormChange(newFormState);
    };

    const handleOnBlur = (event) => {
        const { id, type, checked } = event.target;

        const validField = validateField(id, type === 'checkbox' ? checked : formState[id]);

        let error;
        if (!validField) {
            error = getFieldError(id);
        }

        setFormErrors({ ...formErrors, [id]: error });
    };

    const handleOnClick = async () => {
        try {
            setIsSubmitting(true);
            setSubmitError(undefined);

            const analytics = getItems();

            const body = _.pickBy({
                ...formState,
                vehicleId: vehicle.id,
                vehiclePricingId: vehiclePricing.id,
                sourceUrl: window.location.href,
                analytics,
            }, value => value !== '');

            const response = await apiService.post('onboardingIdentity', body);

            if (response === null || !('data' in response)) throw new Error();

            const { id, sub } = response.data;

            onNextStep({ userId: id, subscriptionId: sub });
        } catch (error) {
            if (error.message.includes('Invalid national')) {
                setSubmitError('El rut ingresado no es válido.');
            } else {
                setSubmitError('Ocurrió un error inesperado.');
            }
        }

        setIsSubmitting(false);
    };

    return (
        <>
            <Paragraph>
                Completa tus datos para comenzar el proceso de solicitud de tu automóvil
            </Paragraph>
            <Input
                className="mt-4"
                placeholder="Nombre"
                id={FIELDS.FIRSTNAME.id}
                error={formErrors[FIELDS.FIRSTNAME.id]}
                value={formState[FIELDS.FIRSTNAME.id] ?? ''}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <Input
                className="mt-4"
                placeholder="Apellidos"
                id={FIELDS.LASTNAME.id}
                error={formErrors[FIELDS.LASTNAME.id]}
                value={formState[FIELDS.LASTNAME.id] ?? ''}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <Input
                className="mt-4"
                placeholder="Email"
                id={FIELDS.EMAIL.id}
                error={formErrors[FIELDS.EMAIL.id]}
                value={formState[FIELDS.EMAIL.id] ?? ''}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <Input
                className="mt-4"
                placeholder="Teléfono"
                id={FIELDS.PHONE.id}
                error={formErrors[FIELDS.PHONE.id]}
                value={formState[FIELDS.PHONE.id] ?? ''}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <Input
                className="mt-4"
                placeholder="Rut"
                id={FIELDS.NATIONAL_ID.id}
                error={formErrors[FIELDS.NATIONAL_ID.id]}
                value={formatRut(formState[FIELDS.NATIONAL_ID.id] ?? '')}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                autoComplete="off"
            />
            <Input
                className="mt-4"
                placeholder="Código de referidos"
                id={FIELDS.COUPON.id}
                error={formErrors[FIELDS.COUPON.id]}
                value={formState[FIELDS.COUPON.id] ?? ''}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <Input
                className="mt-4"
                placeholder={<>Acepto los <a className="checkbox-label-link" href="https://drive.google.com/file/d/1QpkkwmleGEUYJp0Q2YKOXVGXPkUlIqSZ/view?usp=sharing" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></>}
                type="checkbox"
                id={FIELDS.TERMS_AND_CONDITIONS.id}
                error={formErrors[FIELDS.TERMS_AND_CONDITIONS.id]}
                checked={formState[FIELDS.TERMS_AND_CONDITIONS.id] ?? ''}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <Input
                className="mt-4"
                placeholder={<>Acepto la <a className="checkbox-label-link" href="https://drive.google.com/file/d/1vR63BbmfoaSt2WWTcEMfxV99QYohUT84/view?usp=sharing" target="_blank" rel="noopener noreferrer">Política de Tratamiento de Datos Personales</a></>}
                type="checkbox"
                id={FIELDS.PRIVACY_POLICY.id}
                error={formErrors[FIELDS.PRIVACY_POLICY.id]}
                checked={formState[FIELDS.PRIVACY_POLICY.id] ?? ''}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <Button
                className="mt-5 mb-2"
                block
                loading={isSubmitting}
                onClick={handleOnClick}
                disabled={nextDisabled}
            >
                Siguiente
            </Button>
            {
                submitError && (
                    <Paragraph className="mt-3" variant="error" fontSize="xs">
                        { submitError }
                    </Paragraph>
                )
            }
        </>
    );
};

OnboardingIdentityData.propTypes = {
    vehicle: PropTypes.any,
    vehiclePricing: PropTypes.any,
    formState: PropTypes.any,
    onFormChange: PropTypes.func,
    onNextStep: PropTypes.func,
};

OnboardingIdentityData.defaultProps = {
    formState: {},
};

export default OnboardingIdentityData;
