import React from 'react';
import PropTypes from 'prop-types';
import {
    Navigate,
    Outlet,
} from 'react-router-dom';

import sessionService from '../../services/session_service';

const PrivateRoute = (props) => {
    const { loginPath } = props;

    const loggedIn = sessionService.useLoggedIn();

    const currentPath = window?.location?.pathname;

    if (loggedIn && loginPath !== currentPath) return <Outlet />;

    // Not using "pathname" to keep query parameters in "from" string
    const from = window.location.href.replace(window.location.origin, '');

    return <Navigate to={loginPath} state={{ from }} replace />;
};

PrivateRoute.propTypes = {
    loginPath: PropTypes.string.isRequired,
};

export default PrivateRoute;
