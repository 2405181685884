import { useQuery } from '../../hooks/use_query';

import OnboardingPage from './index';
import { useParams } from 'react-router-dom';

const Page = () => {
    const query = useQuery();
    const params = useParams();

    const id = params.vehicleId;

    const periodsAmount = parseInt(query.get('periodsAmount'));
    const periodType = query.get('periodType');
    const annualMileage = parseInt(query.get('annualMileage'));
    const mileageUnitMeasure = query.get('mileageUnitMeasure');

    return (
        <OnboardingPage
            vehicleId={id}
            periodsAmount={periodsAmount}
            periodType={periodType}
            annualMileage={annualMileage}
            mileageUnitMeasure={mileageUnitMeasure}
        />
    );
};

export default Page;
