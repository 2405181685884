import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';

import { clearRut, formatRut } from '../../helpers/formatters';
import apiService from '../../services/api_service';
import useUserIdentityProfile from '../../hooks/use_user_identity_profile';

import Title from '../../components/title';
import Paragraph from '../../components/paragraph';
import Button from '../../components/button';
import Input from '../../components/input';
import Select from '../../components/select';
import Toast from '../../components/toast';
import IconModal from '../../components/icon_modal';

import WarningIcon from '../../assets/icons/warning-icon';
import LockIcon from '../../assets/icons/lock';

import REGIONS from './regions.json';

const FIELDS = {
    RUT: {
        id: 'nationalId',
        regex: /^[1-9]\d{0,2}(\d{3})*[\dk]$/,
        error: 'El rut no es válido, no debe tener puntos ni guión',
        autocomplete: 'off',
        placeholder: 'Rut',
    },
    ADDRESS_STREET: {
        id: 'addressStreet',
        regex: /[\d\w.,]+/,
        error: 'La dirección no es válida',
        autocomplete: 'off',
        placeholder: 'Dirección',
    },
    ADDRESS_REGION: {
        id: 'addressRegion',
        regex: /[\d\w.,]+/,
        error: 'La región no es válida',
        autocomplete: 'off',
        placeholder: 'Selecciona tu región',
        getOptions: (formState) => {
            return REGIONS;
        },
    },
    ADDRESS_CITY: {
        id: 'addressCity',
        regex: /[\d\w.,]+/,
        error: 'La ciudad no es válida',
        autocomplete: 'off',
        placeholder: 'Ciudad',
    },
    ADDRESS_COMMUNE: {
        id: 'addressCommune',
        regex: /[\d\w.,]+/,
        error: 'La comuna no es válida',
        autocomplete: 'off',
        placeholder: 'Selecciona tu comuna',
        getOptions: (formState) => {
            const { addressRegion } = formState;

            const parent = REGIONS.find(item => item.id === addressRegion);

            if (_.isEmpty(parent)) return [];

            return parent.communes;
        },
    },
};

const Page = (props) => {
    const { onNextStep, subscription } = props;

    const [identityProfile, setIdentityProfile] = useUserIdentityProfile();
    const [formState, setFormState] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [nextDisabled, setNextDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState(undefined);
    const [modalDisabled, setModalDisabled] = useState(false);

    useEffect(() => {
        if (_.isEmpty(identityProfile)) return;

        const initialState = {};
        Object.values(FIELDS).forEach(field => {
            initialState[field.id] = identityProfile[field.id];
        });

        setFormState(initialState);
    }, [identityProfile]);

    useEffect(() => {
        const isValid = Object.values(FIELDS).every(field => validateField(field.id, formState[field.id]));

        setNextDisabled(!isValid);
    }, [formState]);

    const validateField = (id, value) => {
        const field = Object.values(FIELDS).find(field => field.id === id);

        if (field === undefined) return false;

        return field.regex.test(value ?? '');
    };

    const getFieldError = (id) => {
        const field = Object.values(FIELDS).find(field => field.id === id);

        if (field === undefined) return false;

        return field.error;
    };

    const handleOnChange = (event) => {
        const { id, value } = event.target;

        const newFormState = { ...formState };

        if (id === FIELDS.ADDRESS_REGION.id) {
            newFormState[id] = value;
            newFormState[FIELDS.ADDRESS_COMMUNE.id] = undefined;
            setFormErrors({
                ...formErrors,
                [id]: undefined,
                [FIELDS.ADDRESS_COMMUNE.id]: undefined,
            });
        } else if (id === FIELDS.RUT.id) {
            newFormState[id] = clearRut(value);
            setFormErrors({ ...formErrors, [id]: undefined });
        } else {
            newFormState[id] = value;
            setFormErrors({ ...formErrors, [id]: undefined });
        }

        setFormState(newFormState);
    };

    const handleOnBlur = (event) => {
        const { id, type, checked } = event.target;

        const validField = validateField(id, type === 'checkbox' ? checked : formState[id]);

        let error;
        if (!validField) {
            error = getFieldError(id);
        }

        setFormErrors({ ...formErrors, [id]: error });
    };

    const handleContractButton = () => {
        setShowModal(true);
    };

    const handleModalButton = async () => {
        try {
            setModalDisabled(true);

            const payload = {};

            Object.keys(formState).forEach(key => {
                if (formState[key] === identityProfile[key]) return;

                payload[key] = formState[key];
            });

            let response;

            if (!_.isEmpty(payload)) {

                response = await apiService.update('userIdentityProfiles', identityProfile.id, payload);

                if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

                setIdentityProfile(response.data);
            }

            response = await apiService.post('subscriptionContracts', { subscriptionId: subscription.id, type: 'SUBSCRIPTION_START' });

            if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

            const result = window.open(response.data.workflowSignatureUrl, '_blank');

            if (result) {
                onNextStep();
            } else {
                setMessage({
                    text: (
                        <p>
                            Tu navegador ha bloqueado la ventana emergente, haz click <Button variant="link" href={response.data.workflowSignatureUrl} target="_blank" rel="noopener noreferrer" onClick={() => onNextStep()} className="inline">aquí</Button> para abrirla.
                        </p>
                    ),
                    level: 'danger',
                });
            }
        } catch (error) {
            let hasFieldError = false;
            Object.keys(formState).forEach(key => {
                if (!error?.message?.includes(key)) return;

                hasFieldError = true;
                const errorMessage = getFieldError(key);
                setFormErrors({ ...formErrors, [key]: errorMessage });
            });

            if (!hasFieldError) {
                setMessage({
                    text: (
                        <p>
                            Nos topamos con un error inesperado, si el problema persiste <Button variant="link" href={`https://wa.me/56954565621?text=Hola%21+Tengo+un+problema+con+la+garantía+de+mi+suscripcion+${subscription.id}`} className="inline" rel="noopener noreferrer">avísanos</Button>
                        </p>
                    ),
                    level: 'danger',
                });
            }
            setShowModal(false);
        }

        setModalDisabled(false);
    };

    const renderFields = (fields) => {
        return Object.values(fields).map(field => {
            const { getOptions } = field;

            const value = field.id === 'nationalId' ? formatRut(formState[field.id]) : formState[field.id];

            if (!_.isFunction(getOptions)) {
                return (
                    <Input
                        key={field.id}
                        className="mt-4"
                        placeholder={field.placeholder}
                        id={field.id}
                        error={formErrors[field.id]}
                        value={value ?? ''}
                        autoComplete={field.autocomplete}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />
                );
            }

            return (
                <Select
                    key={field.id}
                    className="mt-4"
                    id={field.id}
                    value={value ?? ''}
                    error={formErrors[field.id]}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                >
                    <option disabled value="">{field.placeholder}</option>
                    {
                        getOptions(formState).map(option => (
                            <option key={option.id} value={option.id}>{option.name}</option>
                        ))
                    }
                </Select>
            );
        });
    };

    const renderRedirectModal = () => {
        if (!showModal) return;

        return (
            <IconModal
                show={showModal}
                setShow={setShowModal}
                icon={<WarningIcon style={{ width: '2rem', height: '100%', marginRight: '0.5rem' }} />}
                title="Importante"
            >
                <Paragraph className="mx-3" textAlign="center">
                    Se abrirá una nueva pestaña. Una vez que firmes el contrato <b>deberás cerrarla y volver a esta página</b>.
                </Paragraph>
                <br />
                <br />
                {
                    modalDisabled && (
                        <>
                            <Paragraph className="mx-3" textAlign="center" size="xs">
                                Estamos generando el contrato, puede tomar unos minutos.
                            </Paragraph>
                            <br/>
                        </>
                    )
                }
                <Button
                    block
                    variant="primary"
                    onClick={handleModalButton}
                    disabled={modalDisabled}
                    loading={modalDisabled}
                >
                    Entiendo, ir a firmar
                </Button>
                <br />
                <Button block variant="link" onClick={() => setShowModal(false)}>
                    Volver
                </Button>
            </IconModal>
        );
    };

    const renderMessage = (message) => {
        if (_.isEmpty(message)) return null;

        const { text, level } = message;
        return (
            <Toast message={text} level={level} onClose={() => setMessage(undefined)} />
        );
    };

    return (
        <>
            { renderMessage(message) }
            { renderRedirectModal() }
            <Title
                className="mt-3"
                size="md"
                textAlign="center"
            >
                Firma de contrato
            </Title>
            <Paragraph className="mt-3 mx-5" textAlign="center">
                Revisa y completa tus datos faltantes. Una vez listo podrás firmar el contrato.
            </Paragraph>

            <Row>
                <Col xs={12} md={{ span: 8, offset: 2 }}>
                    { renderFields(FIELDS) }
                </Col>
            </Row>

            <Row>
                <Col className="mb-5" xs={12} md={{ span: 8, offset: 2 }}>
                    <Button
                        block
                        disabled={nextDisabled}
                        className="mt-5"
                        variant="primary"
                        onClick={handleContractButton}
                    >
                        Ir a firmar contrato
                    </Button>
                    <Paragraph className="mt-3" size="xxs" textAlign="center">
                        <LockIcon /> La firma será a través del sitio seguro <a href="https://www.webdoxclm.com/" style={{ color: '#0057ff', fontWeight: 'bold', textDecoration: 'none' }}>Webdox</a>
                    </Paragraph>
                </Col>
            </Row>
        </>
    );
};

Page.propTypes = {
    user: PropTypes.any.isRequired,
    subscription: PropTypes.any.isRequired,
    onNextStep: PropTypes.func.isRequired,
};

export default Page;
