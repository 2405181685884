import React from 'react';
import PropTypes from 'prop-types';

import BaseButton from './base_components/button';

const Button = (props) => {
    const { variant } = props;
    let textColor;
    let backgroundColor;
    let borderColor;
    let  borderRadius;

    switch (variant) {
    case 'primary':
        backgroundColor = '#00D880';
        borderColor = '#00D880';
        textColor = '#131353';
        borderRadius = '100rem';
        break;
    case 'secondary':
        backgroundColor = '#131353';
        borderColor = '#131353';
        textColor = 'white';
        borderRadius = '100rem';
        break;
    case 'link':
        backgroundColor = 'transparent';
        borderColor = 'transparent';
        textColor = '#003CA6';
        borderRadius = 10;
        break;
    case 'inverted':
        backgroundColor = '#D9D9D9';
        borderColor = '#D9D9D9';
        textColor = '#003CA6';
        borderRadius = '100rem';
        break;
    default:
        console.error(`Invalid variant property "${variant}" provided to component`);
        return null;
    }

    return (
        <BaseButton
            {...props}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            textColor={textColor}
            borderRadius={borderRadius}
        />
    );
};

Button.propTypes = {
    ...BaseButton.propTypes,
    variant: PropTypes.oneOf([
        'primary', 'secondary', 'link', 'inverted', 'roundedInverted',
    ]),
};

Button.defaultProps = {
    ...BaseButton.defaultProps,
    variant: 'primary',
};

export default Button;
