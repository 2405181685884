import _ from 'lodash';
import { useEffect, useState } from 'react';

import sessionService from '../services/session_service';
import apiService from '../services/api_service';

export default () => {
    const [user, setUser] = useState(undefined);
    const userId = sessionService.useSessionUserId();

    const fetch = async (userId, cancelToken) => {
        if (_.isEmpty(userId)) return;

        const response = await apiService.findOne('users', userId, { populate: true }, cancelToken);

        if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

        setUser(response.data);
    };

    useEffect(() => {
        const cancelToken = apiService.start();

        fetch(userId, cancelToken);

        return () => apiService.cancel(cancelToken);
    }, [userId]);

    return user;
};
