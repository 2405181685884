import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../../components/title';
import Paragraph from '../../components/paragraph';
import Button from '../../components/button';
import Toast from '../../components/toast';

import apiService from '../../services/api_service';

const Page = (props) => {
    const { subscription } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(undefined);

    const handleWarrantyButton = async () => {
        setIsLoading(true);

        try {
            let response = await apiService.findOne('subscriptionWarranties', subscription.id);

            if (_.isEmpty(response) || !Object.keys(response).includes('data')) {
                throw new Error(response.error);
            }

            if (response.data === null) {
                const baseUrl = window.location.href;

                response = await apiService.post('subscriptionWarranties', {
                    subscriptionId: subscription.id,
                    successUrl: `${baseUrl}?status=updating&prevStatus=${subscription.status}`,
                    cancelUrl: `${baseUrl}?status=failure`,
                });
            }

            if (_.isEmpty(response) || !Object.keys(response).includes('data') || response.data === null) {
                throw new Error(response.error);
            }

            window.location.href = response.data.url;
        } catch {
            setIsLoading(false);
            setMessage({
                text: (
                    <p>
                        Nos topamos con un error inesperado, si el problema persiste <Button variant="link" href={`https://wa.me/56954565621?text=Hola%21+Tengo+un+problema+con+la+garantía+de+mi+suscripcion+${subscription.id}`} className="inline">avísanos</Button>
                    </p>
                ),
                level: 'danger',
            });
        }
    };

    const handlePaymentButton = async () => {
        try {
            setIsLoading(true);

            let response = await apiService.findOne('subscriptionPaymentMethods', subscription.id, {
                successUrl: `${window.location.href}?status=updating&prevStatus=${subscription.status}`,
            });

            if (_.isEmpty(response) || !Object.keys(response).includes('data')) {
                throw new Error(response.error);
            }

            if (response.data === null) {
                const baseUrl = window.location.href;

                response = await apiService.post('subscriptionPaymentMethods', {
                    subscriptionId: subscription.id,
                    successUrl: `${baseUrl}?status=updating&prevStatus=${subscription.status}`,
                    cancelUrl: `${baseUrl}?status=failure`,
                });
            }

            if (_.isEmpty(response) || !Object.keys(response).includes('data') || response.data === null) {
                throw new Error(response.error);
            }

            window.location.href = response.data.url;
        } catch (error) {
            setIsLoading(false);
            setMessage({
                text: (
                    <p>
                        Nos topamos con un error inesperado, si el problema persiste <Button variant="link" href={`https://wa.me/56954565621?text=Hola%21+Tengo+un+problema+con+el+pago+de+mi+suscripcion+${subscription.id}`} className="inline">avísanos</Button>
                    </p>
                ),
                level: 'danger',
            });
        }
    };

    const renderMessage = (message) => {
        if (_.isEmpty(message)) return null;

        const { text, level } = message;
        return (
            <Toast message={text} level={level} onClose={() => setMessage(undefined)} />
        );
    };

    return (
        <>
            { renderMessage(message) }
            <Title
                className="mt-3"
                size="md"
                textAlign="center"
            >
                Suscripción al pago
            </Title>
            <Paragraph className="mt-3 mx-5" textAlign="center">
                A continuación suscríbete al pago de nuestro servicio. El proceso de pago se realiza en sitio seguro, para luego volver a este flujo.
            </Paragraph>

            <Row className="mt-5">
                <Col className="text-center" xs={12} md={{ span: 8, offset: 2 }}>
                    <Button
                        block
                        variant="primary"
                        onClick={handleWarrantyButton}
                        disabled={subscription.status !== 'WARRANTY_PENDING'}
                        loading={subscription.status === 'WARRANTY_PENDING' && isLoading}
                    >
                        Pago de garantía
                    </Button>
                </Col>
            </Row>
            <Row className="mt-4 mb-5">
                <Col className="text-center" xs={12} md={{ span: 8, offset: 2 }}>
                    <Button
                        block
                        variant="primary"
                        onClick={handlePaymentButton}
                        disabled={subscription.status !== 'PAYMENT_PENDING'}
                        loading={subscription.status === 'PAYMENT_PENDING' && isLoading}
                    >
                        Suscripción a pago mensual
                    </Button>
                </Col>
            </Row>
        </>
    );
};

Page.propTypes = {
    user: PropTypes.any.isRequired,
    subscription: PropTypes.any.isRequired,
    onNextStep: PropTypes.func.isRequired,
};

export default Page;
