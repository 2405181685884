import _ from 'lodash';

export const clearRut = (rut) => {
    return rut
        .toLowerCase()
        .replace(/\./g, '')
        .replace(/-/g, '')
        .replace(/[^\dk]+$/, '')
        .replace(/k.+$/, 'k')
        .slice(0, 9);
};

export const formatRut = (rut) => {
    if (!_.isString(rut) || _.isEmpty(rut)) return '';

    const cleanRut = clearRut(rut);

    let mapping = '#-###';

    const lenDiff = cleanRut.length - mapping.replace(/[^#]/g, '').length;
    if (lenDiff > 0) {
        mapping += _.repeat('.###', 1 + lenDiff / 3);
    }

    const reversedRut = cleanRut.split('');

    const mappedRut = [];
    mapping.split('').forEach((char) => {
        if (_.isEmpty(reversedRut)) return;

        if (char === '#') {
            mappedRut.push(reversedRut.pop());
        } else {
            mappedRut.push(char);
        }
    });

    return _.reverse(mappedRut).join('');
};
