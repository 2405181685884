import _ from 'lodash';
import { useEffect, useState } from 'react';

import apiService from '../services/api_service';

export default (brand, model, version, year, documentId) => {
    const [item, setItem] = useState(undefined);

    const fetch = async (brand, model, version, year, documentId, cancelToken) => {
        if (_.isEmpty(brand) || _.isEmpty(model) || !_.isNumber(year) || _.isEmpty(documentId)) return;

        try {
            setItem(undefined);

            const response = await apiService.findAll('vehicleImages', { brand, model, version, year, documentId }, cancelToken);

            // Cancelled response
            if (_.isNull(response)) return;

            if (_.isEmpty(response) || !Object.keys(response).includes('data')) {
                throw new Error(response.error);
            }

            if (_.isEmpty(response.data)) {
                throw new Error('Invalid image payload');
            }

            setItem(_.first(response.data));
        } catch {
            setItem(null);
        }
    };

    useEffect(() => {
        const cancelToken = apiService.start();

        fetch(brand, model, version, year, documentId, cancelToken);

        return () => apiService.cancel(cancelToken);
    }, [brand, model, version, year]);

    return [item, setItem];
};
