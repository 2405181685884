import AccordionComponent from '../../components/accordion';
import PropTypes from 'prop-types';

import GreenTicket from '../../assets/icons/green-ticket.svg';
import YellowWarning from '../../assets/icons/yellow-warning.svg';
import LinkIcon from '../../assets/icons/external-link.svg';

import './style.css';
import Button from '../../components/button';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Loader from '../../components/loader';


const TITLES = {
    SPECIAL_USAGE_CERTIFICATE: 'Certificado de antecedentes con fines especiales',
    HOME_ADDRESS_CERTIFICATE: 'Acreditación de Domicilio',
    IDENTITY_CARD_FRONT: 'Cédula de Identidad (Frontal)',
    IDENTITY_CARD_BACK : 'Cédula de Identidad (Trasera)',
};

const renderAccordionItemTitle = (title, isChecked, status, num) => {
    let icon;

    if (isChecked) {
        icon = (
            <img
                src={ status === 'REJECTED' ? YellowWarning : GreenTicket }
                style={{ width: '20px', height:'20px' }}
                alt="done"
            />
        );
    } else {
        icon = (
            <div className="circular-number">
                {num}
            </div>
        );
    }

    return (
        <>
            { icon }
            <span style={{ marginLeft: '11px' }} className="font-weight-bold">
                {title}
            </span>
        </>
    );
};

const PersonalStepDocs = (props) => {
    const { personalDocumentState, handleNewFile } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [activeKey, setActiveKey] = useState('0');
    useEffect(() => {
        const actKey = personalDocumentState.findIndex(document => !document.uploaded);

        setActiveKey(actKey == -1 ? null : actKey.toString());
    }, [personalDocumentState]);

    const handleNewFileWrapper = async (event, doc) => {
        setIsLoading(true);
        await handleNewFile(event, doc, 'onboardingPersonalDocuments');
        setIsLoading(false);
    };
    const renderCheckMark = (isChecked) => {
        return (
            <>
                {isChecked ?
                    <img src={ GreenTicket } className="green-ticket" alt="done"></img>
                    :
                    <></>
                }
            </>
        );
    };

    const renderAccordionItemContent = (subTitle, isChecked, documentId = '', doc, renderTicket = false, renderCamera = false) => {
        if (isChecked) return <></>;
        return (
            <>
                <span className="acc-subtitle">{renderTicket ? renderCheckMark(doc.uploaded) : ''}{subTitle}</span>
                {isLoading ?
                    <Loader/> :
                    <div className="buttons-container">
                        <Form.Control type="file" id={documentId} hidden onChange={(e) => { handleNewFileWrapper(e, doc); }} />
                        <Button block={true} variant="secondary" onClick={(ev) => {document.getElementById(documentId).click();}}>{doc.uploaded ? 'Subir otra versión' : <>{renderCamera ? 'Adjuntar o tomar fotografia' : 'Adjuntar documento'}</>}</Button>
                    </div>}
            </>
        );
    };
    const getSubtitle = (index) => {
        switch (index) {
        case 0:
            return renderLinkContent('Puedes descargarlo en la pagina web del ', 'registro civil', 'https://www.registrocivil.cl/principal/servicios-en-linea');
        case 1:
            return 'Puedes mandarnos una foto de cualquier cuenta (luz, agua, banco, etc) que contenga tu nombre y la dirección donde vives.';
        default:
            return '';

        }
    };
    const renderLinkContent = (preText, text, url) => {
        return (
            <div className="cc-link-content">
                {preText}
                <a className="cc-link"  target={'_blank'} href={url} rel="noreferrer">{text}</a>&nbsp;
                <a className="cc-link"  target={'_blank'} href={url} rel="noreferrer"><img alt={'link'} src={LinkIcon}/></a>
            </div>
        );
    };
    const createNeededDocsArray = () => {
        const pictureAllowedDocs = ['IDENTITY_CARD_FRONT', 'IDENTITY_CARD_BACK'];
        return personalDocumentState.map((doc, index) => {
            return {
                title: renderAccordionItemTitle(TITLES[doc.documentId], doc.uploaded, doc.status, index + 1),
                content: renderAccordionItemContent(getSubtitle(index), false, doc.documentId, doc, false, pictureAllowedDocs.includes(doc.documentId)),
            };
        });
    };

    return (
        <>
            <div className="onboarding-documents__step">
                <AccordionComponent activeKey={activeKey}>
                    {createNeededDocsArray()}
                </AccordionComponent>
            </div>
        </>
    );
};

PersonalStepDocs.propTypes = {
    personalDocumentState: PropTypes.any,
    handleNewFile: PropTypes.func,
};


export default PersonalStepDocs;