import { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import Paragraph from '../../components/paragraph';
import Button from '../../components/button';
import IconModal from '../../components/icon_modal';
import BaseCard from '../../components/base_components/card';

import apiService from '../../services/api_service';

import WarningIcon from '../../assets/icons/warning-icon';
import EyeMulticolor from '../../assets/icons/eye-multicolor.svg';

const ValidationResult = (props) => {
    const { userId, subscriptionId, prevalidationResult, vehiclePricing, onNextStep } = props;

    const [prepaidAmount, setPrepaidAmount] = useState(prevalidationResult.min);
    const [submitError, setSubmitError] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isExiting, setIsExiting] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { periodPrice, periodsAmount, periodType } = vehiclePricing;

    const submitPrepaidAmount = async (amount) => {
        try {
            const response = await apiService.post('onboardingPrepayment', { userId, subId: subscriptionId, prepaidAmount: amount });

            const { prepaidAmount, periodPrice } = response.data;

            const { data } = await apiService.post('onboardingValidate', { userId, subId: subscriptionId });

            return {
                data: {
                    status: data.status,
                    prepaidAmount,
                    periodPrice,
                },
            };
        } catch {
            return null;
        }
    };

    const handleCloseButton = async () => {
        setIsExiting(true);
        await submitPrepaidAmount(0);

        window.location.href = 'https://www.connectcar.cl';
    };

    const handleModalButton = async () => {
        setIsSubmitting(true);
        setSubmitError(undefined);

        const data = await submitPrepaidAmount(prepaidAmount);

        if (data === null) {
            setSubmitError('Tuvimos un problema para enviar tus datos');
        } else {
            onNextStep(data.data);
        }

        setIsSubmitting(false);
    };

    const handleDisplayModal = () => {
        setShowModal(true);
    };

    const renderRedirectModal = () => {
        return (
            <IconModal
                show={showModal}
                setShow={setShowModal}
                icon={<WarningIcon style={{ width: '2rem', height: '100%', marginRight: '0.5rem' }} />}
                title="Importante"
            >
                <Paragraph className="mx-3" textAlign="center">
                    Recuerda que <b>solo podrás optar a tu connect car, utilizando la opción de prepago que elegiste</b>. Si no lo haces, tu solicitud quedará nula.
                </Paragraph>
                <br />
                <br />
                <Button
                    block
                    variant="primary"
                    onClick={handleModalButton}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                >
                    Lo entiendo
                </Button>
                {
                    submitError && (
                        <Paragraph variant="error" textAlign="center">
                            No pudimos enviar la información
                        </Paragraph>
                    )
                }
                <br />
                <Button block variant="link" onClick={() => setShowModal(false)}>
                    Volver
                </Button>
                <br />
            </IconModal>
        );
    };

    const renderPeriodType = () => {
        switch (periodType) {
        case 'DAY':
            return 'diaria';
        case 'WEEK':
            return 'semanal';
        case 'MONTH':
            return 'mensual';
        case 'YEAR':
            return 'anual';
        }
    };

    return (
        <>
            { renderRedirectModal() }
            <div className="text-center my-3">
                <img src={EyeMulticolor} alt="" />
            </div>
            <Paragraph size="sm" textAlign="center" className="mb-5" variant="secondaryNewTheme">
                Elige el monto inicial de prepago que más te acomode para reducir tu cuota mensual.
            </Paragraph>
            <Paragraph size="sm" textAlign="center" fontWeight="bold">
                Prepagando un total de
            </Paragraph>
            <Paragraph size="l" textAlign="center" fontWeight="bold" variant="primaryNewTheme">
                $ { prepaidAmount.toLocaleString().replace(/,/g, '.') }
            </Paragraph>
            <div className="mt-3 mb-5">
                <Form.Range
                    type="range"
                    className="px-4"
                    min={prevalidationResult.min}
                    max={prevalidationResult.max}
                    value={prepaidAmount}
                    step={10000}
                    onChange={(event) => setPrepaidAmount(parseInt(event.target.value)) }
                />
            </div>
            <Paragraph size="sm" textAlign="center" fontWeight="bold">
                Tu cuota { renderPeriodType() } se reduce a
            </Paragraph>
            <Paragraph size="xl" textAlign="center" className="mb-5" fontWeight="bold" variant="primaryNewTheme">
                $ { (periodPrice - Math.round(prepaidAmount / periodsAmount)).toLocaleString().replace(/,/g, '.') }
            </Paragraph>
            <br />
            <br />
            <BaseCard>
                El cobro del prepago se te solicitará posterior a la aprobación de la solicitud.
            </BaseCard>
            <br />
            <Button
                block
                variant="primary"
                onClick={handleDisplayModal}
            >
                Quiero tomar esta opción
            </Button>

            <Button
                block
                variant="inverted"
                className="mt-4 mb-5"
                onClick={handleCloseButton}
                loading={isExiting}
            >
                No me interesa
            </Button>
        </>
    );
};

ValidationResult.propTypes = {
    prevalidationResult: PropTypes.any,
    vehiclePricing: PropTypes.any,
    userId: PropTypes.string,
    subscriptionId: PropTypes.string,
    onNextStep: PropTypes.func,
};

export default ValidationResult;
