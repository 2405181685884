import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import './style.css';

const renderImage = (src, title) => {
    if (_.isEmpty(src)) return null;

    return <img src={src} alt={title}/>;
};

const renderTitle = (title) => {
    return <h3 className="mt-5">{ title } </h3>;
};

const renderSubtitle = (title) => {
    return <p>{ title } </p>;
};

const EmptyState = (props) => {
    const { src, title, subtitle, children, block } = props;

    let className = 'empty-state-container block';
    className = block ? `${className} block` : className;
    return (
        <div className={className}>
            <div>
                { renderImage(src, title) }
                { renderTitle(title) }
                { renderSubtitle(subtitle) }
                { children }
            </div>
        </div>
    );
};

EmptyState.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.node,
    block: PropTypes.bool,
};

EmptyState.defaultProps = {
    block: false,
};

export default EmptyState;
