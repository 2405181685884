import React from 'react';
import PropTypes from 'prop-types';

import BaseButton from '../base_components/button';

import './style.css';

const Button = (props) => {
    const { children, variant, ...nextProps } = props;

    let textColor;
    let backgroundColor;
    let borderColor;
    let borderRadius;

    switch (variant) {
    case 'primary':
        backgroundColor = '#131353';
        textColor = 'white';
        borderColor = '#131353';
        borderRadius = '1.5rem';
        break;

    case 'secondary':
        backgroundColor = '#00D880';
        textColor = 'white';
        borderColor = '#00D880';
        borderRadius = '1.5rem';
        break;

    case 'secondaryOutlined':
        backgroundColor = 'transparent';
        textColor = '#00D880';
        borderColor = '#E1E1E1';
        borderRadius = '0.7rem';
        break;

    case 'danger':
        backgroundColor = 'red';
        textColor = 'white';
        borderColor = 'red';
        borderRadius = '1.5rem';
    }

    return (
        <BaseButton
            {...nextProps}
            block
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            textColor={textColor}
            borderRadius={borderRadius}
            className="sq-container"
        >
            <span className="sq-content">
                { children }
            </span>
        </BaseButton>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.oneOf(['primary', 'secondary', 'secondaryOutlined', 'danger']),
};

Button.defaultProps = {
    variant: 'primary',
};

export default Button;
