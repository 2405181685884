import PropTypes from 'prop-types';
import _ from 'lodash';

import Paragraph from '../paragraph';

import './style.css';

const SelectError = (props) => {
    const { text } = props;

    if (_.isEmpty(text)) return null;

    return (
        <Paragraph size="xs" variant="error">
            { text }
        </Paragraph>
    );
};

SelectError.propTypes = {
    text: PropTypes.string,
};

const Select = (props) => {
    const { error, className, children, ...nextProps } = props;

    return (
        <>
            <div className={`select-div ${className}`}>
                <select className="select-input" {...nextProps} required>
                    { children }
                </select>
            </div>

            <SelectError text={error} />
        </>
    );
};

Select.propTypes = {
    error: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

Select.defaultProps = {
    className: '',
};

export default Select;
