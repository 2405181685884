import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';
import {
    useNavigate,
    useLocation,
    useParams,
    useSearchParams,
    Navigate,
} from 'react-router-dom';

import sessionService from '../../services/session_service';

import StepLogin from './step_login';
import StepPasswordRequest from './step_password_request';
import StepPasswordReset from './step_password_reset';

import ConnectCarLogo from '../../assets/icons/connect-car-logo.svg';

import './style.css';

export const AUTH_STEPS = {
    LOGIN: 'LOGIN',
    PASSWORD_REQUEST: 'REQUEST',
    PASSWORD_RESET: 'RESET',
};

const parseIntialStep = (step) => {
    const initialStep = String(step).toUpperCase();

    if (Object.values(AUTH_STEPS).includes(initialStep)) {
        return initialStep;
    }

    return AUTH_STEPS.LOGIN;
};

const Page = (props) => {
    const { initialStep, postLoginPath } = props;

    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const loggedIn = sessionService.useLoggedIn();

    const [step, setStep] = useState(initialStep);

    const redirectToStep = (step, params = '') => {
        if (_.isEmpty(step)) {
            navigation(postLoginPath);
            return;
        }

        const newUrl = `/auth/${step.toLowerCase()}?${params}`;

        navigation(newUrl);
        setStep(step);
    };

    useEffect(() => {
        const newStep = parseIntialStep(params.step);

        redirectToStep(newStep, searchParams);
    }, [params.step]);

    if (_.isEmpty(step)) return null;

    if (loggedIn && step !== AUTH_STEPS.PASSWORD_RESET) {
        return <Navigate to={location?.state?.from ?? postLoginPath} />;
    }

    const renderAuthStep = (step) => {
        if (step === AUTH_STEPS.LOGIN) {
            return (
                <StepLogin onNext={redirectToStep} />
            );
        }

        if (step === AUTH_STEPS.PASSWORD_REQUEST) {
            const hasEmail = _.isEmpty(searchParams.get('email'));

            let variant = StepPasswordRequest.VARIANT.LOGGEDOUT_REQUEST;
            let button = null;

            if (hasEmail) {
                variant = StepPasswordRequest.VARIANT.FIRST_LOGIN;

                button = (
                    <>
                        <br />
                        <br />
                        <div className="text-center">
                            <button
                                className="auth-back-button"
                                onClick={() => redirectToStep(AUTH_STEPS.LOGIN)}
                            >
                                Volver
                            </button>
                        </div>
                    </>
                );
            }

            return (
                <>
                    <StepPasswordRequest
                        variant={variant}
                        email={searchParams.get('email')}
                        onNext={() => redirectToStep(AUTH_STEPS.LOGIN)}
                    />
                    { button }
                </>
            );
        }

        if (step === AUTH_STEPS.PASSWORD_RESET) {
            return (
                <StepPasswordReset onNext={() => redirectToStep(AUTH_STEPS.LOGIN)} />
            );
        }
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                    <Row className="my-5 text-center">
                        <Col xs={{ offset: 2, span: 8 }}>
                            <img src={ConnectCarLogo} alt="" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pb-5">
                            { renderAuthStep(step) }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

Page.AUTH_STEPS = AUTH_STEPS;

Page.propTypes = {
    postLoginPath: PropTypes.string.isRequired,
    initialStep: PropTypes.oneOf(Object.keys(AUTH_STEPS)),
};

Page.defaultProps = {
    initialStep: undefined,
};

export default Page;
