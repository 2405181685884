import { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import _ from 'lodash';

class SessionService {
    constructor() {
        this.TOKEN_COOKIE_ID = 'tkn';
        this.USER_ID_COOKIE_ID = 'usr';
    }

    storeSessionUserId(userId, expiry) {
        const expiryDate = new Date(0);
        expiryDate.setSeconds(expiry);

        cookie.save(
            this.USER_ID_COOKIE_ID,
            userId,
            { path: '/', expires: expiryDate },
        );
    }

    storeSessionToken(token, expiry) {
        const expiryDate = new Date(0);
        expiryDate.setSeconds(expiry);

        cookie.save(
            this.TOKEN_COOKIE_ID,
            token,
            { path: '/', expires: expiryDate },
        );
    }

    getSessionToken() {
        return cookie.load(this.TOKEN_COOKIE_ID) ?? null;
    }

    getSessionUserId() {
        return cookie.load(this.USER_ID_COOKIE_ID) ?? null;
    }

    removeSession() {
        cookie.remove(this.TOKEN_COOKIE_ID, { path: '/' });
        cookie.remove(this.USER_ID_COOKIE_ID, { path: '/' });
    }

    isLoggedIn() {
        const token = this.getSessionToken();
        const userId = this.getSessionUserId();

        return !_.isEmpty(token) && !_.isEmpty(userId);
    }

    useLoggedIn() {
        const [loggedIn, setLoggedIn] = useState(this.isLoggedIn());

        useEffect(() => {
            const cookieCheckerCb = () => setLoggedIn(this.isLoggedIn());

            const timer = setInterval(cookieCheckerCb, 500);

            return () => clearInterval(timer);
        }, [setLoggedIn]);

        return loggedIn;
    }

    useSessionUserId() {
        const [userId, setUserId] = useState(this.getSessionUserId());

        useEffect(() => {
            const cookieCheckerCb = () => setUserId(this.getSessionUserId());

            const timer = setInterval(cookieCheckerCb, 500);

            return () => clearInterval(timer);
        }, [setUserId]);

        return userId;
    }
}

const sessionService = new SessionService();

export default sessionService;
