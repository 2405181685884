import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Paragraph from '../../components/paragraph';
import Button from '../../components/button';
import Select from '../../components/select';

import apiService from '../../services/api_service';

const FIELDS = {
    CONTRACT_TYPE: {
        id: 'contract_type',
        regex: /^.+$/,
        error: 'Debes seleccionar una situación laboral',
    },
    INCOME_RANGE: {
        id: 'income_range',
        regex: /^.+$/,
        error: 'Debes seleccionar un rango de sueldo',
    },
    WORKING_ANTIQUITY: {
        id: 'working_antiquity',
        regex: /^.+$/,
        error: 'Debes seleccionar una antiguedad laboral',
    },
};

const OnboardingWorkData = (props) => {
    const { userId, formState, onNextStep, onFormChange } = props;
    const [formErrors, setFormErrors] = useState({});
    const [nextDisabled, setNextDisabled] = useState(true);
    const [submitError, setSubmitError] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateField = (id, value) => {
        const field = Object.values(FIELDS).find(field => field.id === id);

        if (field === undefined) return false;

        return field.regex.test(value ?? '');
    };

    const getFieldError = (id) => {
        const field = Object.values(FIELDS).find(field => field.id === id);

        if (field === undefined) return false;

        return field.error;
    };

    useEffect(() => {
        const isValid = Object.values(FIELDS).every(field => validateField(field.id, formState[field.id]));

        setNextDisabled(!isValid);
    }, [formState]);

    const handleOnChange = (event) => {
        const { id, value } = event.target;

        const newFormState = {
            ...formState,
            [id]: value,
        };

        onFormChange(newFormState);
        setFormErrors({ ...formErrors, [id]: undefined });
    };

    const handleOnBlur = (event) => {
        const { id, value } = event.target;

        const validField = validateField(id, value);

        let error;
        if (!validField) {
            error = getFieldError(id);
        }

        setFormErrors({ ...formErrors, [id]: error });
    };

    const handleOnClick = async () => {
        try {
            setIsSubmitting(true);
            setSubmitError(undefined);
            await apiService.post('onboardingWork', { ...formState, userId });

            onNextStep();
        } catch {
            setSubmitError('Tuvimos un problema para enviar tus datos');
        }
        setIsSubmitting(false);
    };

    return (
        <>
            <Paragraph>
                Para continuar completa los siguientes datos laborales
            </Paragraph>
            <Select
                className="mt-4"
                id={FIELDS.CONTRACT_TYPE.id}
                value={formState[FIELDS.CONTRACT_TYPE.id] ?? ''}
                error={formErrors[FIELDS.CONTRACT_TYPE.id]}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            >
                <option value="" disabled>Situación Laboral</option>
                <option value="Dependent">Dependiente</option>
                <option value="Independent">Independiente</option>
            </Select>
            <Select
                className="mt-4"
                id={FIELDS.INCOME_RANGE.id}
                value={formState[FIELDS.INCOME_RANGE.id] ?? ''}
                error={formErrors[FIELDS.INCOME_RANGE.id]}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            >
                <option value="" disabled>Rango de Sueldo</option>
                <option value="RANGE_0_14">Menos de $1.500.000</option>
                <option value="RANGE_15_19">$1.500.000 - $1.999.999</option>
                <option value="RANGE_20_24">$2.000.000 - $2.499.999</option>
                <option value="RANGE_25_29">$2.500.000 - $2.999.999</option>
                <option value="RANGE_30_34">$3.000.000 - $3.499.999</option>
                <option value="RANGE_35_39">$3.500.000 - $3.999.999</option>
                <option value="RANGE_40">Más de $4.000.000</option>
            </Select>
            <Select
                className="mt-4"
                id={FIELDS.WORKING_ANTIQUITY.id}
                value={formState[FIELDS.WORKING_ANTIQUITY.id] ?? ''}
                error={formErrors[FIELDS.WORKING_ANTIQUITY.id]}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            >
                <option value="" disabled>Antiguedad Laboral</option>
                <option value="RANGE_0_4">0 - 4 meses</option>
                <option value="RANGE_5">5 ó más meses</option>
            </Select>
            <Button
                className="mt-5"
                block
                loading={isSubmitting}
                onClick={handleOnClick}
                disabled={nextDisabled}
            >
                Siguiente
            </Button>
            {
                submitError && (
                    <Paragraph variant="error" textAlign="center">
                        No pudimos enviar la información
                    </Paragraph>
                )
            }
        </>
    );
};

OnboardingWorkData.propTypes = {
    userId: PropTypes.string,
    formState: PropTypes.any,
    onFormChange: PropTypes.func,
    onNextStep: PropTypes.func,
};

OnboardingWorkData.defaultProps = {
    formState: {},
};

export default OnboardingWorkData;
