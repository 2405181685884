import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Accordion from 'react-bootstrap/Accordion';

import './style.css';

function AccordionComponent(props) {
    const { children, activeKey } = props;

    const [actKey, setActKey] = useState(activeKey);

    useEffect(() => {
        setActKey(activeKey);
    }, [activeKey]);

    return (
        <Accordion defaultActiveKey="0" className="accordion-cc" activeKey={actKey}>
            {children.map((children, index) => (
                <Accordion.Item key={index.toString()} eventKey={index.toString()} onClick={() => setActKey(index.toString())}>
                    <Accordion.Header >{children.title}</Accordion.Header>
                    <Accordion.Body>{children.content}</Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}
//props validation
AccordionComponent.propTypes = {
    children: PropTypes.arrayOf(
        PropTypes.any,
    ),
    activeKey: PropTypes.string,
};

AccordionComponent.defaultProps = {
    children: [],
    activeKey: '0',
};

export default AccordionComponent;