import _ from 'lodash';
import { useEffect, useState } from 'react';

import apiService from '../services/api_service';

export default (brand, model, version, year, status) => {
    const [vehicle, setItem] = useState(undefined);

    const fetch = async (brand, model, version, year, status, cancelToken) => {
        if (_.isEmpty(brand) || _.isEmpty(model)) return;

        const response = await apiService.findAll('findAvailableVehicles', { brand, model, version, year, status }, { populate: true }, cancelToken);

        if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

        setItem(response.data);
    };

    useEffect(() => {
        const cancelToken = apiService.start();

        fetch(brand, model, version, year, status, cancelToken);

        return () => apiService.cancel(cancelToken);
    }, [brand, model, version, year, status]);

    return [vehicle, setItem];
};
