import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const MenuBrand = (props) => {
    const { className, ...nextProps } = props;

    const cssClass = className ? `menu-brand ${className}` : 'menu-brand';

    return (
        <img className={cssClass} {...nextProps} alt="Menu Brand" />
    );
};

MenuBrand.propTypes = {
    className: PropTypes.string,
};

MenuBrand.defaultProps = {
    className: undefined,
};

const MenuButton = (props) => {
    const { className, onClick, ...nextProps } = props;

    const cssClass = className ? `menu-button ${className}` : 'menu-button';

    const handleOnClick = (event) => {
        event.target.classList.toggle('cross');

        return onClick(event);
    };

    return (
        <button className={cssClass} onClick={handleOnClick} {...nextProps}>
            <span />
            <span />
            <span />
        </button>
    );
};

MenuButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

MenuButton.defaultProps = {
    className: undefined,
    onClick: () => {},
};

const MenuHeader = (props) => {
    const { children, onClick } = props;

    const childrenWithProps = React.Children.map(children, child => {
        if (child.type === MenuButton) {
            return React.cloneElement(child, { ...child.props, onClick });
        }

        return child;
    });

    return (
        <div className="menu-header">
            { childrenWithProps }
        </div>
    );
};

MenuHeader.Brand = MenuBrand;
MenuHeader.Button = MenuButton;

MenuHeader.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
};

MenuHeader.defaultProps = {
    className: undefined,
    onClick: () => {},
};

const MenuBodyButton = (props) => {
    const { icon, children, onClick, isActive, textAlign } = props;

    let cssClass = isActive ? 'menu-body-link active' : 'menu-body-link';

    cssClass = textAlign ? `${cssClass} ${textAlign}` : cssClass;

    let iconElement = null;

    if (icon) {
        const Icon = icon;
        iconElement = <Icon />;
    }

    return (
        <button type="button" className={cssClass} onClick={onClick}>
            { iconElement } <span>{ children }</span>
        </button>
    );
};

MenuBodyButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    icon: PropTypes.any,
    textAlign: PropTypes.oneOf([
        'center',
        'left',
        'right',
    ]),
};

MenuBodyButton.defaultProps = {
    isActive: false,
    textAlign: 'left',
};

const MenuBodyLink = (props) => {
    const { icon, children, href, isActive } = props;

    const cssClass = isActive ? 'menu-body-link active' : 'menu-body-link';

    let image = null;
    if (icon) {
        image = <img src={icon} className="menu-body-link-icon" alt="icon" />;
    }

    return (
        <a className={cssClass} href={href}>
            {image}{ children }
        </a>
    );
};

MenuBodyLink.propTypes = {
    children: PropTypes.node,
    href: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    icon: PropTypes.string,
};

MenuBodyLink.defaultProps = {
    isActive: false,
};

const MenuBodyGroup = (props) => {
    const { scroll, children } = props;

    return (
        <div style={{ overflowY: scroll ? 'scroll' : 'unset' }}>
            { children }
        </div>
    );
};

MenuBodyGroup.propTypes = {
    scroll: PropTypes.bool,
    children: PropTypes.node,
};

MenuBodyGroup.defaultProps = {
    scroll: false,
};

const MenuBody = (props) => {
    const { isOpen, children } = props;

    const cssClass = isOpen ? 'menu-body open' : 'menu-body';
    const overlayCssClass = isOpen ? 'menu-body-overlay open' : 'menu-body-overlay';

    return (
        <>
            <div className={cssClass}>
                { children }
            </div>
            <div className={overlayCssClass} />
        </>
    );
};

MenuBody.Link = MenuBodyLink;
MenuBody.Button = MenuBodyButton;
MenuBody.Group = MenuBodyGroup;

MenuBody.propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.node,
};

const Menu = (props) => {
    const { children, isOpen, setOpen } = props;

    const expandMenu = () => setOpen(!isOpen);

    const childrenWithProps = React.Children.map(children, child => {
        if (child.type === MenuHeader) {
            return React.cloneElement(child, { ...child.props, onClick: expandMenu });
        }

        if (child.type === MenuBody) {
            return React.cloneElement(child, { ...child.props, isOpen });
        }

        return child;
    });

    return (
        <div className="menu">
            { childrenWithProps }
        </div>
    );
};

Menu.Header = MenuHeader;
Menu.Body = MenuBody;

Menu.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default Menu;
