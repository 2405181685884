import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import MainRouter from './main_router';

import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const rootNode = (
    <React.StrictMode>
        <BrowserRouter>
            <MainRouter />
        </BrowserRouter>
    </React.StrictMode>
);

root.render(rootNode);
