import React from 'react';
import _ from 'lodash';
import {
    useParams,
} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

import SubscriptionCard from '../../components/subscription_card';
import Loader from '../../components/loader';
import Paragraph from '../../components/paragraph';
import Title from '../../components/title';
import Table from '../../components/table';
import Button from '../../components/button';
import QuickAccessButtons from '../../components/quick_access_buttons';

import useSubscription from '../../hooks/use_subscription';
import useSubscriptionDocuments from '../../hooks/use_subscription_documents';

import MainIcon from '../../assets/icons/connect-car-icon.svg';
import DocumentIcon from '../../assets/icons/document';
import CarTowIcon from '../../assets/icons/car-tow';
import DownloadIcon from '../../assets/icons/download';

const Page = () => {
    const { subscriptionId } = useParams();

    const [subscription] = useSubscription(subscriptionId);

    const [documents] = useSubscriptionDocuments({ subscriptionId });

    if (_.isUndefined(subscription)) {
        return (
            <div style={{ marginTop: '5rem' }}>
                <Loader icon={MainIcon} />
            </div>
        );
    }

    const renderEmergenciesAccordion = () => {
        return (
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Paragraph variant="primaryNewTheme" fontWeight="bold">
                        <Paragraph.Secondary><CarTowIcon /></Paragraph.Secondary>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Emergencias
                    </Paragraph>
                </Accordion.Header>

                <Accordion.Body>
                    <Paragraph fontWeight="bold">
                        ¿Necesitas ayuda?
                    </Paragraph>
                    <br/>
                    <Paragraph size="xs">
                        Elige la opción que más se acomode a tu necesidad
                    </Paragraph>
                    <br/>
                    <Row>
                        <Col>
                            <QuickAccessButtons
                                thefts
                                collisions
                                assistance
                            />
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        );
    };

    const parseDocumentType = (type) => {
        if (type === 'CONTRACT') {
            return 'Contrato';
        }

        return type;
    };

    const parseDocumentSubtype = (subtype) => {
        if (subtype === 'SUBSCRIPTION_START') {
            return 'Inicio subscripción';
        }

        return subtype;
    };

    const renderDocumentsAccordion = () => {
        if (!_.isUndefined(documents) && !_.isArray(documents)) return;

        let body;
        let subtitle;

        if (_.isUndefined(documents)) {
            body = (
                <div className="my-4">
                    <Loader icon={MainIcon} />
                </div>
            );
        } else if (!_.isEmpty(documents)) {
            subtitle = 'Acá están todos los documentos asociados a tu suscripción.';
            body = (
                <Table>
                    <thead>
                        <tr>
                            <th>
                                Tipo
                            </th>
                            <th>
                                Categoría
                            </th>
                            <th>
                                Documento
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.isArray(documents) && documents.map(document => (
                                <tr key={document.id}>
                                    <td>
                                        { parseDocumentType(document.type) }
                                    </td>
                                    <td>
                                        { parseDocumentSubtype(document.subtype) }
                                    </td>
                                    <td>
                                        <Button
                                            key={document.id}
                                            href={document.url}
                                            className="inline"
                                            variant="link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Descargar <Paragraph.Secondary><DownloadIcon /></Paragraph.Secondary>
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            );
        } else {
            subtitle = 'No tienes documentos disponibles en este momento';
        }

        return (
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <Paragraph variant="primaryNewTheme" fontWeight="bold">
                        <DocumentIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Documentos
                    </Paragraph>
                </Accordion.Header>
                <Accordion.Body>
                    <Paragraph fontWeight="bold">
                        ¿Necesitas algún documento?
                    </Paragraph>
                    <br/>
                    <Paragraph size="xs">
                        { subtitle }
                    </Paragraph>
                    <br/>
                    { body }
                </Accordion.Body>
            </Accordion.Item>
        );
    };

    return (
        <>
            <Row>
                <Col>
                    <Title variant="secondaryNewTheme">
                        Mi suscripción
                    </Title>
                </Col>
            </Row>
            <br/>
            <br/>
            <Row>
                <Col xs={12} md={5}>
                    <SubscriptionCard variant="simple" subscription={subscription} index={0} />
                </Col>
            </Row>
            <br/>
            <Accordion className="accordion-cc">
                { renderEmergenciesAccordion() }
                { renderDocumentsAccordion() }
            </Accordion>
        </>
    );
};

export default Page;
