const SUBSCRIPTION_STATUS = {
    // No action performed over subscription or waiting user input
    CREATED: 'CREATED',

    // Waiting for admin review
    REVIEW_REQUIRED: 'REVIEW_REQUIRED',

    // Subscription was rejected
    REJECTED: 'REJECTED',

    // User complies with minimum requirements
    PREAPPROVED: 'PREAPPROVED',

    // Subscription is waiting contract sign
    CONTRACT_PENDING: 'CONTRACT_PENDING',

    // Subscription is waiting for warranty payment
    WARRANTY_PENDING: 'WARRANTY_PENDING',

    // Subscription is waiting payment method
    PAYMENT_PENDING: 'PAYMENT_PENDING',

    // Subscription is waiting devilvery schedule
    SCHEDULING_PENDING: 'SCHEDULING_PENDING',

    // Subscription is waiting vehicle delivery
    DELIVERY_PENDING: 'DELIVERY_PENDING',

    // Subscription is waiting delivery certificate sign
    DELIVERY_CERTIFICATE_PENDING: 'DELIVERY_CERTIFICATE_PENDING',

    // Subscription is active and can be charged to user
    ACTIVE: 'ACTIVE',

    // Subscription is suspended and charges should be stopped
    SUSPENDED: 'SUSPENDED',

    // Subscription is closed and charges should be stopped
    CLOSED: 'CLOSED',
};

export const ACTIVE_STATUSES = [
    SUBSCRIPTION_STATUS.ACTIVE,
    SUBSCRIPTION_STATUS.SUSPENDED,
];

export const PENDING_STATUSES = [
    SUBSCRIPTION_STATUS.REVIEW_REQUIRED,
    SUBSCRIPTION_STATUS.PREAPPROVED,
    SUBSCRIPTION_STATUS.CONTRACT_PENDING,
    SUBSCRIPTION_STATUS.WARRANTY_PENDING,
    SUBSCRIPTION_STATUS.PAYMENT_PENDING,
    SUBSCRIPTION_STATUS.SCHEDULING_PENDING,
    SUBSCRIPTION_STATUS.DELIVERY_PENDING,
    SUBSCRIPTION_STATUS.DELIVERY_CERTIFICATE_PENDING,
];

export const OTHER_STATUSES = [
    SUBSCRIPTION_STATUS.CLOSED,
    SUBSCRIPTION_STATUS.REJECTED,
];

export default SUBSCRIPTION_STATUS;
