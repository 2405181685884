import React from 'react';
import PropTypes from 'prop-types';

import Title from '../../components/title';

import './style.css';
import Paragraph from '../../components/paragraph';
import Button from '../../components/button';

const Step = (props) => {
    const { title, subtitle, buttonText, onClick } = props;

    return (
        <>
            <br/>
            <Title textAlign="center" size="sm">
                { title }
            </Title>
            <br/>
            <br/>
            {
                subtitle && (
                    <>
                        <Paragraph textAlign="center">
                            { subtitle }
                        </Paragraph>
                        <br/>
                        <br/>
                    </>
                )
            }
            {
                (buttonText && onClick) && (
                    <Button
                        block
                        onClick={onClick}
                        variant="primary"
                    >
                        { buttonText }
                    </Button>
                )
            }
        </>
    );
};

Step.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
};

export default Step;
