import React from 'react';
import PropTypes from 'prop-types';

const BaseTitle = (props) => {
    const {
        children,
        size,
        textAlign,
        color,
        fontWeight,
        className,
    } = props;

    const style = { fontWeight, color, textAlign };

    switch (size) {
    case 'xs':
        style.fontSize = '1rem';

        return <h4 style={style} className={className}>{ children }</h4>;
    case 'sm':
        style.fontSize = '1.2rem';

        return <h3 style={style} className={className}>{ children }</h3>;
    case 'md':
        style.fontSize = '1.5rem';

        return <h2 style={style} className={className}>{ children }</h2>;
    case 'l':
        style.fontSize = '2rem';

        return <h1 style={style} className={className}>{ children }</h1>;
    case 'xl':
        style.fontSize = '2.5rem';

        return <h1 style={style} className={className}>{ children }</h1>;
    case 'xxl':
        style.fontSize = '3rem';

        return <h1 style={style} className={className}>{ children }</h1>;
    default:
        break;
    }

    return null;
};

BaseTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    size: PropTypes.oneOf([
        'xs', 'sm', 'md', 'l', 'xl',
    ]),
    textAlign: PropTypes.oneOf([
        'unset', 'left', 'right', 'center',
    ]),
    color: PropTypes.string,
    fontWeight: PropTypes.oneOf([
        'light', 'medium', 'bold',
    ]),
    className: PropTypes.string,
};

BaseTitle.defaultProps = {
    size: 'l',
    textAlign: 'unset',
    color: 'black',
    fontWeight: 'bold',
    className: '',
};

export default BaseTitle;
