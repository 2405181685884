import React from 'react';
import PropTypes from 'prop-types';

import BaseTitle from './base_components/title';

const Title = (props) => {
    const { variant } = props;

    let color;

    switch (variant) {
    case 'primary':
        color = '#003CA6';
        break;
    case 'secondary':
        color = 'white';
        break;
    case 'primaryNewTheme':
        color = '#3330E8';
        break;
    case 'secondaryNewTheme':
        color = '#131353';
        break;
    case 'dark':
        color = '#2D2D2D';
        break;
    case 'grey':
        color = '#6B6B6B';
        break;
    default:
        console.error(`Invalid variant property "${variant}" provided to component`);
        return null;
    }

    return <BaseTitle {...props} color={color} />;
};

Title.propTypes = {
    ...BaseTitle.propTypes,
    variant: PropTypes.oneOf([
        'primary', 'secondary', 'dark', 'grey', 'primaryNewTheme', 'secondaryNewTheme',
    ]),
};

Title.defaultProps = {
    ...BaseTitle.defaultProps,
    variant: 'primary',
    fontWeight: 'bold',
};

export default Title;
