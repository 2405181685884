import PropTypes from 'prop-types';

import './style.css';

import Step from './step';

const ProgressStepper = (props) => {
    const { steps, labels, currentStep } = props;

    const renderStep = (step, index, steps) => {
        const leftActive = currentStep >= index ? 'active' : 'disabled';
        const rightActive = currentStep > index ? 'active' : 'disabled';

        return <Step
            key={step + index}
            left={index === 0 ? 'hidden' : leftActive }
            dot={currentStep >= index ? 'active' : 'disabled' }
            right={index >= steps.length - 1 ? 'hidden' : rightActive }
        />;
    };

    const renderLabel = (label, index, _labels) => {
        return (
            <p
                key={label + index}
                className="text-center"
            >
                { label }
            </p>
        );
    };

    return (
        <>
            <div className="cc-stepper-container">
                { steps.map(renderStep) }
            </div>
            <div className="cc-stepper-container">
                { (labels ?? steps).map(renderLabel)}
            </div>
        </>
    );
};

ProgressStepper.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string),
    currentStep: PropTypes.number,
};

ProgressStepper.defaultProps = {
    currentStep: 0,
    labels: null,
};

export default ProgressStepper;
