import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../../components/title';
import Paragraph from '../../components/paragraph';
import Button from '../../components/button';

import { useCalendlyEventListener, PopupModal } from 'react-calendly';

const Page = (props) => {
    const { user, subscription, onNextStep } = props;

    const [isOpen, setIsOpen] = useState(false);

    const onEventScheduled = () => {
        onNextStep();
    };

    useCalendlyEventListener({ onEventScheduled });

    return (
        <>
            <Title
                className="mt-3"
                size="md"
                textAlign="center"
            >
                Agendamiento de entrega
            </Title>

            <Paragraph className="mt-3 mx-5" textAlign="center">
                Selecciona el día y hora que quieres recibir tu connect car en tu casa.
            </Paragraph>

            <PopupModal
                url="https://calendly.com/nicodeconnect/agendamiento-de-entrega"
                prefill={{
                    email: user.email,
                    name: `${user.firstname} ${user.lastname}`,
                }}
                utm={{
                    utmContent: JSON.stringify({ subscriptionId: subscription.id, email: user.email }),
                }}
                rootElement={document.getElementById('root')}
                text="Agendar"
                onModalClose={() => setIsOpen(false)}
                open={isOpen}
            />

            <Row>
                <Col className="text-center" xs={12} md={{ span: 8, offset: 2 }}>
                    <Button
                        block
                        className="mt-5"
                        variant="primary"
                        onClick={() => setIsOpen(true)}
                    >
                        Agendar
                    </Button>
                </Col>
            </Row>
        </>
    );
};

Page.propTypes = {
    user: PropTypes.any.isRequired,
    subscription: PropTypes.any.isRequired,
    onNextStep: PropTypes.func.isRequired,
};

export default Page;
