import _ from 'lodash';
import { useEffect, useState } from 'react';

import apiService from '../services/api_service';
import sessionService from '../services/session_service';

export default (id) => {
    const [item, setItem] = useState(undefined);
    const userId = sessionService.useSessionUserId();

    const fetch = async (id, query, cancelToken) => {
        if (_.isEmpty(id)) return;

        setItem(undefined);

        const response = await apiService.findOne('subscriptions', id, { ...query, populate: true }, cancelToken);

        if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

        setItem(response.data);
    };

    useEffect(() => {
        const cancelToken = apiService.start();

        fetch(id, { userId }, cancelToken);

        return () => apiService.cancel(cancelToken);
    }, [id, userId]);

    return [item, setItem];
};
