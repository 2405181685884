export default () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2924 6.56971L14.9458 6.20277L13.7295 2.92413C13.5223 2.37033 12.9922 2.0034 12.4011 2H3.71353C3.12235 2 2.59233 2.37033 2.38508 2.92413L1.18575 6.162L0.686306 6.62407C0.251419 6.9944 0 7.53801 0 8.1088V12.8858C0.00339755 13.6672 0.63874 14.2991 1.42018 14.2991H2.31713C3.09857 14.2991 3.73391 13.6672 3.73731 12.8858V12.1927H12.1632V12.8858C12.1666 13.6672 12.802 14.2991 13.5834 14.2991H14.4804C15.2618 14.2991 15.8972 13.6672 15.9006 12.8858V7.98309C15.8972 7.44968 15.6797 6.94004 15.2924 6.56971ZM3.02382 3.15517C3.13254 2.86638 3.40775 2.67951 3.71353 2.67951H12.4045C12.7103 2.67951 12.9855 2.86977 13.0942 3.15517L14.1542 6.00911H13.3626C13.1757 4.73503 11.99 3.85506 10.7159 4.04533C9.70002 4.19482 8.90159 4.99325 8.7521 6.00911H1.96379L3.02382 3.15517ZM12.6695 6.00911H9.43841C9.62527 5.11556 10.5018 4.54477 11.3954 4.73163C12.0341 4.86754 12.5336 5.36698 12.6695 6.00911ZM3.0578 12.8858C3.0544 13.2935 2.72484 13.6196 2.31713 13.6196H1.42018C1.01247 13.6196 0.682908 13.2935 0.679511 12.8858V11.9956C0.883364 12.1281 1.15177 12.1927 1.42018 12.1927H3.0578V12.8858ZM15.221 12.8858C15.2176 13.2935 14.8881 13.6196 14.4804 13.6196H13.5834C13.1757 13.6196 12.8462 13.2901 12.8428 12.8858V12.1927H14.4804C14.7488 12.1927 15.0172 12.1281 15.221 11.9956V12.8858ZM14.4804 11.5132H1.42018C1.01587 11.5165 0.682908 11.1938 0.679511 10.7861V8.1088C0.679511 7.73507 0.845991 7.37833 1.13139 7.1371C1.13478 7.1337 1.14158 7.12691 1.14498 7.12351L1.60704 6.68862H14.4736L14.7997 7.03857C14.8031 7.04537 14.8133 7.04877 14.8167 7.05556C15.0716 7.29679 15.2176 7.63314 15.2176 7.98309V10.7861H15.221C15.2176 11.1904 14.8847 11.5165 14.4804 11.5132Z" fill="currentColor" />
        <path d="M4.48484 8.04761H1.93668C1.74981 8.04761 1.59692 8.2005 1.59692 8.38736V10.1541C1.59692 10.341 1.74981 10.4938 1.93668 10.4938H4.48484C4.67171 10.4938 4.8246 10.341 4.8246 10.1541V8.38736C4.8246 8.2005 4.67171 8.04761 4.48484 8.04761ZM4.14509 9.81434H2.27643V8.72712H4.14509V9.81434Z" fill="currentColor" />
        <path d="M13.9638 8.04761H11.4157C11.2288 8.04761 11.0759 8.2005 11.0759 8.38736V10.1541C11.0759 10.341 11.2288 10.4938 11.4157 10.4938H13.9638C14.1507 10.4938 14.3036 10.341 14.3036 10.1541V8.38736C14.3036 8.2005 14.1507 8.04761 13.9638 8.04761ZM13.6241 9.81434H11.7554V8.72712H13.6241V9.81434Z" fill="currentColor" />
        <path d="M9.59128 9.47449H6.30924C6.12237 9.47449 5.96948 9.62738 5.96948 9.81424C5.96948 10.0011 6.12237 10.154 6.30924 10.154H9.59128C9.77814 10.154 9.93103 10.0011 9.93103 9.81424C9.93103 9.62738 9.77814 9.47449 9.59128 9.47449Z" fill="currentColor" />
        <path d="M9.59128 8.42139H6.30924C6.12237 8.42139 5.96948 8.57428 5.96948 8.76114C5.96948 8.94801 6.12237 9.1009 6.30924 9.1009H9.59128C9.77814 9.1009 9.93103 8.94801 9.93103 8.76114C9.93103 8.57428 9.77814 8.42139 9.59128 8.42139Z" fill="currentColor" />
    </svg>

);
