import { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Title from '../../components/title';
import Paragraph from '../../components/paragraph';
import Button from '../../components/button';

import apiService from '../../services/api_service';

import CheckCircle from '../../assets/icons/check-circle.svg';
import ErrorCircle from '../../assets/icons/error-circle.svg';

const STATUS = {
    NEEDS_MANUAL_VALIDATION: 'NMV',
    CAN_CONTINUE_FLOW: 'CCF',
    INVALID_DELAY_REJECTION: 'IDR',
    NEEDS_PREPAYMENT_TO_CONTINUE: 'NPC',
};

const ValidationResult = (props) => {
    const { currentStep, userId, subscriptionId, onNextStep } = props;
    const [isExiting, setIsExiting] = useState(false);

    const handleCloseButton = async () => {
        setIsExiting(true);

        await apiService.post('onboardingPrepayment', {
            userId,
            subId: subscriptionId,
            prepaidAmount: 0,
        });

        window.location.href = 'https://www.connectcar.cl';
    };

    const renderIcon = () => {
        switch (currentStep) {
        case STATUS.NEEDS_MANUAL_VALIDATION:
        case STATUS.CAN_CONTINUE_FLOW:
        case STATUS.INVALID_DELAY_REJECTION:
            return (
                <Row>
                    <Col className="text-center">
                        <img src={CheckCircle} alt="" />
                    </Col>
                </Row>
            );
        case STATUS.REJECTED:
        case STATUS.NEEDS_PREPAYMENT_TO_CONTINUE:
            return (
                <Row>
                    <Col className="text-center">
                        <img src={ErrorCircle} alt="" />
                    </Col>
                </Row>
            );
        }
    };

    const renderTitle = () => {
        switch (currentStep) {
        case STATUS.NEEDS_MANUAL_VALIDATION:
        case STATUS.CAN_CONTINUE_FLOW:
        case STATUS.INVALID_DELAY_REJECTION:
            return (
                <Title textAlign="center" variant="dark" className="my-4">
                    ¡Listo!
                </Title>
            );
        case STATUS.NEEDS_PREPAYMENT_TO_CONTINUE:
            return (
                <Title textAlign="center" variant="dark" className="my-4">
                    ¡Ups!
                </Title>
            );
        }
    };

    const renderParagraph = () => {
        switch (currentStep) {
        case STATUS.NEEDS_MANUAL_VALIDATION:
        case STATUS.INVALID_DELAY_REJECTION:
            return (
                <>
                    <Paragraph size="sm" textAlign="center" className="mb-5" fontWeight="bold">
                        Todos tus datos fueron recibidos correctamente.
                    </Paragraph>
                    <Paragraph variant="link" textAlign="center" className="mb-5">
                        Dentro de las póximas <b>24 horas</b> te llegará un correo con el resultado de tu solicitud.
                    </Paragraph>
                </>
            );
        case STATUS.CAN_CONTINUE_FLOW:
            return (
                <>
                    <Paragraph size="sm" textAlign="center" className="mb-5" fontWeight="bold">
                        Todos tus datos fueron recibidos correctamente.
                    </Paragraph>
                    <Paragraph variant="link" textAlign="center" className="mb-5">
                        Puedes continuar el proceso de <b>validación de datos personales y documentos solicitados.</b>
                    </Paragraph>
                </>
            );
        case STATUS.NEEDS_PREPAYMENT_TO_CONTINUE:
            return (
                <>
                    <Paragraph size="sm" textAlign="center" className="mb-5">
                        El rango de sueldo que declaraste no cumple con los requisitos.
                    </Paragraph>
                    <Paragraph variant="link" textAlign="center" className="mb-5">
                        Te ofrecemos la opción de prepagar un monto inicial y así reducir el monto mensual de tu connect car.
                    </Paragraph>
                </>
            );
        }
    };

    const renderHelp = () => {
        return (
            <Paragraph size="xs" textAlign="center" className="mb-5">
                Si tienes alguna duda o consulta, nos puedes escríbir directo a nuestro <a target="_blank" href="https://api.whatsapp.com/send/?phone=56954565621&text&type=phone_number&app_absent=0" rel="noreferrer">WhatsApp</a>
            </Paragraph>
        );
    };

    const renderButtons = () => {
        switch (currentStep) {
        case STATUS.NEEDS_MANUAL_VALIDATION:
        case STATUS.INVALID_DELAY_REJECTION:
            return (
                <Button block href="https://www.connectcar.cl" className="mb-5">
                    Cerrar
                </Button>
            );
        case STATUS.CAN_CONTINUE_FLOW:
            return (
                <Link
                    to={{
                        pathname: '/documents',
                        search: '?userId=' + userId + '&subscriptionId=' + subscriptionId,
                    }}
                >
                    <Button block className="mb-5">
                        Siguiente
                    </Button>
                </Link>
            );
        case STATUS.NEEDS_PREPAYMENT_TO_CONTINUE:
            return (
                <>
                    <Button block variant="primary" onClick={onNextStep}>
                        Me interesa prepagar
                    </Button>
                    <Button
                        block
                        variant="inverted"
                        className="mt-4 mb-5"
                        onClick={handleCloseButton}
                        loading={isExiting}
                    >
                        No me interesa
                    </Button>
                </>
            );
        }
    };

    return (
        <>
            { renderIcon() }
            { renderTitle() }
            { renderParagraph() }
            { renderHelp() }
            { renderButtons() }
        </>
    );
};
ValidationResult.propTypes = {
    currentStep: PropTypes.string,
    userId: PropTypes.string,
    subscriptionId: PropTypes.string,
    onNextStep: PropTypes.func,
};


export default ValidationResult;
